Array.prototype.asyncForEach = async function (callback) {
	for (let i = 0; i < this.length; i++) {
		await callback(this[i], i, this)
	}
}

Array.prototype.clear = function () {
	this.splice(0, this.length)
}

Array.prototype.hasOneAndOnlyOne = function (callback) {
	let result = false
	const filteredEntries = this.filter(e => callback(e))
	if (filteredEntries && filteredEntries.length == 1) {
		result = true
	}
	return result
}
