import Axios from 'axios'
import { Config } from '@/services/ConfigService'
import { ConcurrencyManager } from 'axios-concurrency'

const AxiosInstance = Axios.create({
	baseURL: Config.VUE_APP_ROOT_API + '/api/',
	withCredentials: false,
	responseType: 'json',
	responseEncoding: 'utf8',
	validateStatus: function (status) {
		return status >= 200 && status < 300
	}
})

export { Axios }

const MAX_CONCURRENT_REQUESTS = 50

export const BackEndApiManager = ConcurrencyManager(AxiosInstance, MAX_CONCURRENT_REQUESTS)
