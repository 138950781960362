export default {
	namespaced: true,
	state: {
		cancelTokens: {
			listCatalogServicesOfCategory: null
		},
		categories: [],
		category: null
	},
	mutations: {
		addCategory: function (state, category) {
			state.categories.push(category)
		},
		removeCategory: function (state, categoryId) {
			const categoryIndex = state.categories.findIndex(c => c.id === categoryId)
			if (categoryIndex >= 0) {
				state.categories.splice(categoryIndex, 1)
			}
		},
		replaceCategory: function (state, category) {
			const categoryIndex = state.categories.findIndex(c => c.id === category.id)
			if (categoryIndex >= 0) {
				Object.assign(state.categories[categoryIndex], category)
			}
		},
		setCancelToken: function (state, data) {
			if (state.cancelTokens.hasOwnProperty(data.item)) {
				state.cancelTokens[data.item] = data.cancelToken
			}
		},
		setCategories: function (state, categories) {
			state.categories = categories
		},
		setCategory: function (state, category) {
			state.category = category
		},
		reset: function (state) {
			state.cancelTokens.listCatalogServicesOfCategory = null
			state.categories = []
			state.category = null
		}
	},
	actions: {
		addCategory: function ({ commit }, category) {
			return commit('addCategory', category)
		},
		removeCategory: function ({ commit }, categoryId) {
			return commit('removeCategory', categoryId)
		},
		replaceCategory: function ({ commit }, category) {
			return commit('replaceCategory', category)
		},
		setCancelToken: function ({ commit }, data) {
			return commit('setCancelToken', data)
		},
		setCategories: function ({ commit }, categories) {
			return commit('setCategories', categories)
		},
		setCategory: function ({ commit }, category) {
			return commit('setCategory', category)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
