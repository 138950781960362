import Vue from 'vue'

export default {
	namespaced: true,
	state: {
		accountingFirmId: null,
		list: [],
		name: '',
		oauth_providers: [],
		selected: {}
	},
	mutations: {
		select: function (state, data) {
			Object.entries(data).forEach(([key, value]) => {
				if (state.selected.hasOwnProperty(key)) {
					state.selected[key] = value
				} else {
					Vue.set(state.selected, key, value)
				}
			})
		},
		setWhiteLabel: function (state, data) {
			state.list.clear()
			if (data.list) {
				data.list.forEach(whiteLabel => {
					state.list.push(whiteLabel)
				})
			}
			state.accountingFirmId = data.accountingFirmId
			state.name = data.name ?? 'Welyb'
		},
		reset: function (state) {
			state.logo = ''
			state.name = ''
			state.primary_color = ''
			state.secondary_color = ''
		}
	},
	actions: {
		select: function ({ commit }, whiteLabel) {
			return commit('select', whiteLabel)
		},
		set: function ({ commit }, data) {
			return commit('setWhiteLabel', data)
		},
		reset: function ({ commit }) {
			commit('reset')
		},
	}
}
