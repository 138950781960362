import { mapState } from "vuex";
import OS from '@/helpers/os'
import Keyboard from '@/helpers/keyboard'
import { RequiredArguments } from '@/helpers/methods'

export default {
	data: function () {
		return {
			keyboard: Keyboard,
			KeyboardListenerMixinEventsActionsMapping: []
		}
	},
	computed: {
		...mapState({
			pauseKeyboardListening: state => state.pauseKeyboardListening
		}),
	},
	created: function () {
		this.___initializeKeyboardEventsActionsMapping()
	},
	mounted: function () {
		this.___startListeningToKeyboard()
	},
	destroyed: function () {
		this.___stopListeningToKeyboard()
	},
	methods: {
		___isKeyboardEventsActionsMappingValid: function (keyboardEventsActionsMapping) {
			return (
				keyboardEventsActionsMapping &&
				keyboardEventsActionsMapping.every(
					keyboardEventsActions =>
						keyboardEventsActions?.hasOwnProperty('actions') &&
						keyboardEventsActions.actions.every(
							keyboardEventsAction => keyboardEventsAction.hasOwnProperty('key') && keyboardEventsAction.hasOwnProperty('action')
						)
				)
			)
		},
		___getKeyboardEventsActionsMapping: function (keyboardEventsActionsMapping) {
			return keyboardEventsActionsMapping.map(keyboardEventsMapping => {
				return {
					querySelector: keyboardEventsMapping.querySelector,
					actions: keyboardEventsMapping.actions
						.filter(keyboardEventsAction => !keyboardEventsAction.os || keyboardEventsAction.os.includes(OS.current))
						.map(keyboardEventsAction => {
							return this.___getKeyboardEventsActions(
								keyboardEventsMapping.keyboardEvent || this.keyboard.events.keyUp,
								keyboardEventsAction.key,
								keyboardEventsAction.modifiers || [],
								keyboardEventsAction.action,
								keyboardEventsAction.stopPropagation ?? true,
								keyboardEventsAction.preventDefault ?? true
							)
						})
				}
			})
		},
		___getKeyboardEventsActions: function (keyboardEvent, keyboardKey, keyboardModifiers, keyboardAction, doStopPropagation, preventDefault) {
			RequiredArguments({
				keyboardEvent,
				keyboardKey,
				keyboardModifiers,
				keyboardAction,
				doStopPropagation,
				preventDefault,
			})
			return {
				keyboardEvent: keyboardEvent,
				keyboardAction: (event) => {
					const isMappedKey = this.keyboard.helpers.eventEqual(event, keyboardKey)
					let isMappedModifiers
					if (keyboardModifiers.length > 0) {
						isMappedModifiers = keyboardModifiers.every(keyboardModifier => this.keyboard.helpers.eventHasModifier(event, keyboardModifier))
					} else {
						isMappedModifiers = Object.values(this.keyboard.modifiers).every(
							keyboardModifier => !this.keyboard.helpers.eventHasModifier(event, keyboardModifier)
						)
					}
					if (isMappedKey && isMappedModifiers && !this.pauseKeyboardListening) {
						const doPreventDefault = typeof preventDefault === 'function' ? preventDefault(event) : preventDefault;
						if (doPreventDefault) {
							event.preventDefault()
						}
						keyboardAction(event)
						if (doStopPropagation) {
							event.stopImmediatePropagation()
							event.stopPropagation()
						}
					}
				},
			}
		},
		___initializeKeyboardEventsActionsMapping: function () {
			if (
				this.hasOwnProperty('getKeyboardEventsActionsMapping') &&
				typeof this.getKeyboardEventsActionsMapping == 'function'
			) {
				const keyboardEventsActionsMapping = this.getKeyboardEventsActionsMapping()
				if (this.___isKeyboardEventsActionsMappingValid(keyboardEventsActionsMapping)) {
					this.KeyboardListenerMixinEventsActionsMapping = this.___getKeyboardEventsActionsMapping(keyboardEventsActionsMapping)
				} else {
					throw new Error('keyboardEventsActionsMapping is incorrect', keyboardEventsActionsMapping)
				}
			}
		},
		___startListeningToKeyboard: function () {
			this.KeyboardListenerMixinEventsActionsMapping.forEach(keyboardEventsActionsMapping => {
				const domElements = keyboardEventsActionsMapping.querySelector ? document.querySelectorAll(keyboardEventsActionsMapping.querySelector) : [document]
				domElements?.forEach(domElement => {
					keyboardEventsActionsMapping.actions.forEach(keyboardEventAction => {
						domElement.addEventListener(keyboardEventAction.keyboardEvent, keyboardEventAction.keyboardAction)
					})
				})
			})
		},
		___stopListeningToKeyboard: function () {
			this.KeyboardListenerMixinEventsActionsMapping.forEach(keyboardEventsActionsMapping => {
				const domElements = keyboardEventsActionsMapping.querySelector ? document.querySelectorAll(keyboardEventsActionsMapping.querySelector) : [document];
				domElements?.forEach(domElement => {
					keyboardEventsActionsMapping.actions.forEach(keyboardEventAction => {
						domElement.removeEventListener(keyboardEventAction.keyboardEvent, keyboardEventAction.keyboardAction)
					})
				})
			})
		},
		resetKeyboardListening: function () {
			this.___stopListeningToKeyboard()
			this.___initializeKeyboardEventsActionsMapping()
			this.___startListeningToKeyboard()
		}
	}
}
