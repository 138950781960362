const DocumentPreviewFrame = () => import('@/modules/DocumentPreviewFrame')

export default [
	{
		component: DocumentPreviewFrame,
		meta: function (route) {
			const result = {}
			if (route.query?.newTab == 'true') {
				result.refresh_token = false
			}
			return result
		},
		name: 'documents-preview-folder',
		path: '/viewer/:context/:folderHash/:documentHash/:versionId?',
		props: route => {
			const props = { ...route.params }
			props.newTab = route.query?.newTab == 'true'
			return props
		}
	}
]
