// Container
const AuthTeamManager = () => import('@/modules/TeamManager')

// Pages
const CompanyAssignmentSection = () => import('@/components/TeamManager/CompanyAssignmentSection')
const ThemeAssignmentSection = () => import('@/components/Themes/TeamManager/ThemeAssignmentSection')
const UserManagementSection = () => import('@/components/TeamManager/UserManagementSection')

export default [
	{
		component: AuthTeamManager,
		name: 'team',
		path: 'team-manager',
		redirect: {
			name: 'team-manager-users'
		},

		children: [
			{
				component: CompanyAssignmentSection,
				name: 'team-manager-companies',
				path: 'companies'
			},
			{
				component: ThemeAssignmentSection,
				name: 'team-manager-themes',
				path: 'themes'
			},
			{
				component: UserManagementSection,
				name: 'team-manager-users',
				path: 'users'
			}
		]
	}
]
