import { NodeFactory } from "@/classes/Documents/NodeFactory.class";

const defaultCurrentDocument = () =>  ({
	hash: null,
	id: null,
})

export default {
	namespaced: true,
	state: {
		documents: [],
		current: defaultCurrentDocument(),
		preventKeyboardKeys: [],
	},
	mutations: {
		setDocuments: function (state, documents) {
			state.documents = [...documents];
		},
		addDocument: function (state, document) {
			const index = state.documents.findIndex((doc) => doc.hash === document.hash);
			if (index !== -1) {
				state.documents.splice(index, 1);
			}
			state.documents.push(document);
		},
		setCurrent: function (state, document) {
			state.current = NodeFactory.createNode({...document});
		},
		resetCurrent: function (state) {
			state.current = defaultCurrentDocument();
		},
		setCurrentByHash: function (state, hash) {
			state.current = state.documents.find(function (document) {
				return document.hash === hash;
			});
		},
		setPreventKeyboardKeys: function (state, keys) {
			state.preventKeyboardKeys = keys;
		},
		addPreventKeyboardKey: function (state, key) {
			state.preventKeyboardKeys.push(key);
		},
	},
	getters: {
		currentIndex: function (state) {
			const index = state.documents.findIndex((document) => document?.hash === state.current?.hash);

			return index === -1 ? 0 : index;
		},

		hasPreventKeyboardKey: (state) => (key) => state.preventKeyboardKeys.includes(key),
	},
	actions: {
		selectNextDocument: function (context) {
			if (context.getters.currentIndex < context.state.documents.length - 1) {
				context.commit('setCurrentByHash', context.state.documents[context.getters.currentIndex + 1].hash);
			}
		},
		selectPreviousDocument: function (context) {
			if (context.getters.currentIndex > 0) {
				context.commit('setCurrentByHash', context.state.documents[context.getters.currentIndex - 1].hash);
			}
		},
	}
}
