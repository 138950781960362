import { router as ROUTER } from '@/router'
import AuthService from '@/services/Auth/AuthService'

export default function (to, from) {
	let result = true

	const meta = ROUTER.getMeta(to)
	const isAccountant = AuthService.getIsAccountant()

	if ((meta.hasOwnProperty('accountant') && !meta.accountant && isAccountant) || (meta.hasOwnProperty('client') && !meta.client && !isAccountant)) {
		const fromMeta = ROUTER.getMeta(from)
		if (!from || from.fullPath == '/' || !fromMeta.hasOwnProperty('authentication_required')) {
			result = {
				name: 'home'
			}
		} else {
			result = false
		}
	}

	return result
}
