export default function (to, from) {
	let result = true

	if (from && from.query.hasOwnProperty('embedded') && to && !to.query.hasOwnProperty('embedded')) {
		to.query.embedded = from.query.embedded
        result = to
	}

	return result
}
