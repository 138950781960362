import Vue from 'vue'

export default {
	namespaced: true,
	state: {
		list: [],
		selected: null,
		userRoles: {
			isAdmin: false,
			isCollab: false,
			isGuest: false
		}
	},
	mutations: {
		addToList: function (state, company) {
			state.list.push(company)
		},
		updateCompany: function (state, updatedCompany) {
			state.list.map(company => {
				if (company.id === updatedCompany.id) {
					Object.assign(company, updatedCompany)
				}
			})
		},
		setList: function (state, val) {
			state.list.clear()
			state.list =  state.list = val.map(company => ({
				...company,
				notifications: company.notifications ?? 0,
				loadedCounter: company.loadedCounter ?? false,
			}));
		},
		setListDetails: function (state, val) {
			state.list.map(company => {
				val.map(detailedCompany => {
					if (detailedCompany.id === company.id) {
						Object.entries(detailedCompany).forEach(([key, value]) => {
							if (!company.hasOwnProperty(key)) {
								Vue.set(company, key, value)
							}
						})
					}
				})
			})
			val.map(detailedCompany => {
				if (state.list.filter(company => company.id === detailedCompany.id).length === 0) {
					state.list.push(detailedCompany)
				}
			})
		},
		setSelected: function (state, val) {
			state.selected = val
		},
		setUserRole: function (state, role) {
			state.userRoles.isAdmin = role.key == 'accountant' || role.key == 'admin'
			state.userRoles.isCollab = role.key == 'collaborator'
			state.userRoles.isGuest = role.key == 'guest'
			state.userRoles.value = role.od
		},
		setSelectedAllowedThemes: function (state, val) {
			state.selected.allowed_themes = val
		},
		updateName: function (state, name) {
			state.selected.company = name
		},
		reset: function (state) {
			state.list = []
			state.selected = null
		}
	},
	actions: {
		addCompany: function ({ commit }, company) {
			return commit('addToList', company)
		},
		updateCompany: function ({ commit }, company) {
			return commit('updateCompany', company)
		},
		setAllowedThemes: function ({ commit }, themes) {
			return commit('setSelectedAllowedThemes', themes)
		},
		setCompanies: function ({ commit }, companies) {
			return commit('setList', companies)
		},
		setCompany: function ({ commit }, company) {
			return commit('setSelected', company)
		},
		setCompanyUserRole: function ({ commit }, role) {
			return commit('setUserRole', role)
		},
		setCompaniesDetails: function ({ commit }, companies) {
			return commit('setListDetails', companies)
		},
		updateName: function ({ commit }, name) {
			return commit('updateName', name)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
