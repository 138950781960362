import { Config } from '@/services/ConfigService'
import { Axios } from '@/plugins/axios'

import qs from 'qs'

import Interceptor from '@/plugins/axios/DefaultInterceptor'
import UploaderManager from '@/plugins/axios/UploadManagerSingleton'

const RequestsPriority = {
	low: 'low',
	high: 'high',
	auto: 'auto'
}

const defaultConfiguration = baseUrl => {
	const axios = Axios.create({
		baseURL: baseUrl,
		withCredentials: false,
		responseType: 'json',
		responseEncoding: 'utf8',
		validateStatus: function (status) {
			return status >= 200 && status < 300
		},
		paramsSerializer: function (params) {
			return qs.stringify(params, { arrayFormat: 'brackets' })
		}
	})
	axios.interceptors.request.use(Interceptor.onRequestSuccess, Interceptor.onRequestError)
	axios.interceptors.response.use(Interceptor.onResponseSuccess, Interceptor.onResponseError.bind({ axios: axios }))
	axios.chunkUpload = (url, file, params) => {
		return UploaderManager.upload(`${baseUrl}${url}`, file, params)
	}
	return axios
}

export const Configurator = {
	defaultConfiguration: defaultConfiguration,
	CancelToken: Axios.CancelToken
}

class AxiosRequester {
	_runningRequests
	_axios
	_baseUrl
	constructor (baseURL) {
		this._runningRequests = []
		this._axios = defaultConfiguration(baseURL)
		this._baseUrl = baseURL
	}
	_cancelPreviousRequest (method, uri, args, parameters) {
		if (method == 'GET') {
			const previousRequest = this._getPreviousRequest(method, uri, args, parameters)
			if (previousRequest) {
				if (previousRequest instanceof AbortController) {
					previousRequest.abort()
				} else {
					previousRequest.cancelTokenSource.cancel(previousRequest.cancelToken)
				}
			}
		}
	}
	_cancelAllOtherRequests (method, uri) {
		if (method == 'GET') {
			this._runningRequests
				.filter(runningRequest => runningRequest.method == method && runningRequest.uri == uri)
				.forEach(runningRequest => {
					if (runningRequest instanceof AbortController) {
						runningRequest.abort()
					} else {
						runningRequest.cancelTokenSource.cancel(runningRequest.cancelToken)
					}
				})
		}
	}
	_getPreviousRequest (method, uri, args, parameters = {}) {
		const url = uri(...args)
		return this._runningRequests.find(
			(runningRequest) => {
				if (parameters == null) {
					parameters = {}
				}
				
				let result = method == runningRequest.method && url == runningRequest.url

				if (result) {
					const params = runningRequest.params ?? {}
					if (Object.keys(parameters).length != Object.keys(params).length) {
						result = false
					} else {
						for (const [key, value] of Object.entries(parameters)) {
							if (value != params[key]) {
								result = false
								break
							}
						}
					}
					
				}

				return result
			}
		)
	}
	_recordNewRunningRequest (method, uri, args, parameters, data, newCancelSource, response) {
		this._runningRequests.push({
			method: method,
			uri: uri,
			args: args,
			url: uri(...args),
			params: parameters,
			data: data,
			cancelTokenSource: newCancelSource,
			cancelToken: newCancelSource.token,
			response: response
		})
	}
	_clearRunningRequest (method, url, parameters = null) {
		const runningRequestIndex = this._runningRequests.findIndex(
			runningRequest => runningRequest.method == method && runningRequest.url == url && runningRequest.params == parameters
		)
		if (runningRequestIndex != -1) {
			this._runningRequests.splice(runningRequestIndex, 1)
		}
	}
	_runFetchApiRequest (method, url, parameters, data, newCancelSource, importance) {
		return fetch(`${Config.VUE_APP_ROOT_API}${url}`, {
			method: method,
			headers: new Headers({
				Accept: 'application/json',
				'Accept-Encoding': 'gzip, deflate, br',
				Authorization: localStorage.getItem('token'),
				Host: Config.VUE_APP_ROOT_API
			}),
			mode: 'cors',
			cache: 'default',
			referrer: window.location,
			keepalive: false,
			priority: importance,
			signal: newCancelSource.signal
		})
			.catch(e => {
				if (e.name !== 'AbortError') {
					throw e
				}
			})
			.then(response => response.json())
			.then(response => response.data)
	}
	_runRequest (method, url, parameters, data, newCancelToken, chunkUpload = false) {
		const request = {
			url: url,
			method: method.toLowerCase(),
			cancelToken: newCancelToken
		}
		if (parameters) {
			request.params = parameters
		}
		if (data) {
			request.data = data
		}
		let result = null
		if (chunkUpload) {
			result = this._axios.chunkUpload(url, data, parameters)
		} else {
			result = this._axios.request(request)
		}
		return (
			result
				//TODO: FIX BACKEND to have homogeneous responses
				.then(response => {
					if (!response?.data) {
						result = null
					} else if (response.data.hasOwnProperty('data') && !response.data.hasOwnProperty('pagination')) {
						result = response.data.data
					} else {
						result = response.data
					}
					return result
				})
				.catch(error => {
					if (!Axios.isCancel(error)) {
						throw error
					}
				})
		)
	}
	_execRequest (
		useFetchApi,
		method,
		uri,
		args = [],
		params = null,
		data = null,
		doCancelPreviousRequest = false,
		doCancelAllOtherRequests = false,
		chunkUpload = false,
		importance = RequestsPriority.auto
	) {
		const url = uri(...args)
		const parameters = params
		let result
		const previousRequest = this._getPreviousRequest(method, uri, args, parameters)
		if (previousRequest && method == 'GET') {
			result = Promise.resolve(previousRequest.response)
		} else {
			if (doCancelAllOtherRequests) {
				this._cancelAllOtherRequests(method, uri)
			}
			if (doCancelPreviousRequest) {
				this._cancelPreviousRequest(method, uri, args, parameters)
			}
			let newCancelSource
			if (useFetchApi) {
				newCancelSource = new AbortController()
				result = this._runFetchApiRequest(method, url, parameters, data, newCancelSource, importance).finally(() => {
					this._clearRunningRequest(method, url, parameters)
				})
			} else {
				newCancelSource = Configurator.CancelToken.source()
				result = this._runRequest(method, url, parameters, data, newCancelSource.token, chunkUpload).finally(() => {
					this._clearRunningRequest(method, url, parameters)
				})
			}
			this._recordNewRunningRequest(method, uri, args, parameters, data, newCancelSource, result)
		}
		return result
	}
	getRequest (uri, args, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'GET', uri, args, params, null, doCancelPreviousRequest, doCancelAllOtherRequests, false)
	}
	postRequest (uri, args, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'POST', uri, args, params, data, doCancelPreviousRequest, doCancelAllOtherRequests, false)
	}
	patchRequest (uri, args, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'PATCH', uri, args, params, data, doCancelPreviousRequest, doCancelAllOtherRequests, false)
	}
	putRequest (uri, args, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'PUT', uri, args, params, data, doCancelPreviousRequest, doCancelAllOtherRequests, false)
	}
	deleteRequest (uri, args, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'DELETE', uri, args, params, null, doCancelPreviousRequest, doCancelAllOtherRequests, false)
	}
	chunkUpload (uri, args, params = null, file = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
		return this._execRequest(false, 'POST', uri, args, params, file, doCancelPreviousRequest, doCancelAllOtherRequests, true)
	}
	FetchApiGetRequest (uri, args, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false, importance = RequestsPriority.auto) {
		return this._execRequest(true, 'GET', uri, args, params, null, doCancelPreviousRequest, doCancelAllOtherRequests, false, importance)
	}
}

export const Requester = function (baseURL) {
	const axiosRequester = new AxiosRequester(baseURL)
	return {
		GET: function (...args) {
			return axiosRequester.getRequest(...args)
		},
		POST: function (...args) {
			return axiosRequester.postRequest(...args)
		},
		PATCH: function (...args) {
			return axiosRequester.patchRequest(...args)
		},
		PUT: function (...args) {
			return axiosRequester.putRequest(...args)
		},
		DELETE: function (...args) {
			return axiosRequester.deleteRequest(...args)
		},
		CHUNKUPLOAD: function (...args) {
			return axiosRequester.chunkUpload(...args)
		},
		fetchApi: {
			GET: function (...args) {
				return axiosRequester.FetchApiGetRequest(...args)
			},
			priority: RequestsPriority
		}
	}
}

export const Backend = Requester(Config.VUE_APP_ROOT_API)
