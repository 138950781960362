import { router as ROUTER } from '@/router'
import AuthService from '@/services/Auth/AuthService'

export default function (to) {
	let result = true
	if (
		to.matched.some(record => {
			const meta = ROUTER.getMeta(record)
			return meta.authentication_required
		}) &&
		!AuthService.getIsAuthenticated()
	) {
		result = {
			name: 'signin',
			query: {}
		}
		if (window.location.pathname != '/') {
			result.query.redirect = encodeURIComponent(window.location.pathname + window.location.search)
		}
	}
	return result
}
