import frFR from './ressources/fr-FR__datetimeFormats.json'
import enUS from './ressources/en-US__datetimeFormats.json'
import ptPT from './ressources/pt-PT__datetimeFormats.json'

export default {
	fr: frFR,
	'fr-FR': frFR,
	'en-US': enUS,
	'pt-PT': ptPT
}
