import { AbstractModuleGuardFactory } from '@/mixins/ModulesGuards/AbstractModuleGuard'
import AbstractModuleGuard from "@/mixins/ModulesGuards/AbstractModuleGuard";

const coreMixin = {
	data: function () {
		return {
			moduleName: null
		}
	},
	computed: {
		hasModule: function () {
			return true
		}
	}
}

export function AppModuleGuardFactory(options = {}) {
	return {
		...coreMixin,
		mixins: [
			AbstractModuleGuardFactory(options),
		]
	}
}

export default {
	...coreMixin,
	mixins: [
		AbstractModuleGuard,
	]
};
