import NotifyMatomo from '@/router/plugins/NotifyMatomo'
import NotifyHubspot from '@/router/plugins/NotifyHubspot'

const Plugins = []
// WARNING: Order matters
Plugins.push(NotifyMatomo)
Plugins.push(NotifyHubspot)

export default function (to, from, next) {
	let result = true
	for (const plugin of Plugins) {
		result = plugin(to, from, next)
		if (result !== true) {
			break
		}
	}
	return result
}
