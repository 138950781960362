import frFR from './ressources/fr-FR__messages.json'
import enUS from './ressources/en-US__messages.json'
import ptPT from './ressources/pt-PT__messages.json'

export default {
	fr: frFR,
	en: enUS,
	pt: ptPT,
	'fr-FR': frFR,
	'en-US': enUS,
	'pt-PT': ptPT
}
