export default {
	namespaced: true,
	state: {
		current: null,
	},
	mutations: {
		setCurrent: function (state, dialogName) {
			state.current = dialogName;
		},
	},
	actions: {
		reset: function ({ commit }) {
			commit('setCurrent', null);
		},
	}
}
