import applications from '@/router/routes/auth/settings/applications'
import analytics from '@/router/routes/auth/settings/analytics'
import customization from '@/router/routes/auth/settings/customization'
import documentTemplates from '@/router/routes/auth/settings/documentTemplates'
import ecmCatalog from '@/router/routes/auth/settings/ecmCatalog'
import groupAdministration from '@/router/routes/auth/settings/groupAdministration'
import groupManager from '@/router/routes/auth/settings/groupManager'
import home from '@/router/routes/auth/settings/home'
import offers from '@/router/routes/auth/settings/offers'
import quickfolderManager from '@/router/routes/auth/settings/quickfolderManager'
import teamManager from '@/router/routes/auth/settings/teamManager'
import termsOfService from '@/router/routes/auth/settings/termsOfService'

const Settings = () => import('@/components/Settings/AFSettings')

export default [
	{
		component: Settings,
		meta: {
			client: false,
			context: {
				vendor_id: 'never'
			}
		},
		name: 'accounting-firm-settings',
		redirect: { name: 'accounting-firm-settings-home' },
		path: 'settings/:context?',
		children: [
			...applications,
			...analytics,
			...customization,
			...documentTemplates,
			...ecmCatalog,
			...groupAdministration,
			...groupManager,
			...home,
			...offers,
			...quickfolderManager,
			...teamManager,
			...termsOfService
		]
	}
]
