const WorkspaceManager = () => import('@/modules/WorkspaceManager')
const WorkspaceDrawer = () => import('@/components/Workspaces/WorkspaceDrawer')

export default [
	{
		component: WorkspaceManager,
        meta: {
            accountant: false
        },
        name: 'workspaces',
        path: 'workspaces',
        children: [
            {
                component: WorkspaceDrawer,
                name: 'workspaces-workspace',
                path: ':workspaceId',
                props: route => {
                    return {
                        value: route.params.workspaceId == 'create' ? null : parseInt(route.params.workspaceId)
                    }
                }
            }
        ]
	}
]