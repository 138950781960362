export default {
	namespaced: true,
	state: {
		cancelTokens: {
			listProjects: null,
			listServices: null,
			listServicesOfCategory: null
		},
		display: {
			content: null
		},
		filters: {
			categories: [],
			onlyCurrentProjects: false,
			search: null,
			statuses: []
		},
		loaders: {
			listCategories: false,
			listStatuses: false
		},
		pagination: {
			descending: false,
			page: 1,
			rowsPerPage: 15,
			sortBy: 'name',
			totalItems: 0
		},
		categories: [],
		statuses: []
	},
	mutations: {
		resetFilters: function (state) {
			state.filters.categories = []
			state.filters.onlyCurrentProjects = false
			state.filters.search = null
		},
		setCancelToken: function (state, data) {
			if (state.cancelTokens.hasOwnProperty(data.item)) {
				state.cancelTokens[data.item] = data.cancelToken
			}
		},
		setCategories: function (state, categories) {
			state.categories = categories
		},
		setContentDisplayMode: function (state, contentDisplayMode) {
			state.display.content = contentDisplayMode
		},
		setLoader: function (state, { key, value }) {
			if (state.loaders.hasOwnProperty(key)) {
				state.loaders[key] = value
			}
		},
		setOnlyCurrentProjects: function (state, value) {
			state.filters.onlyCurrentProjects = value
		},
		setPagination: function (state, paginationData) {
			Object.assign(state.pagination, paginationData)
		},
		setSearchedCategories: function (state, categories) {
			state.filters.categories = categories
		},
		setSearchedStatuses: function (state, statuses) {
			state.filters.statuses = statuses
		},
		setSearchedText: function (state, search) {
			state.filters.search = search
		},
		setStatuses: function (state, statuses) {
			state.statuses = statuses
		},
		reset: function (state) {
			state.cancelTokens.listProjects = null
			state.cancelTokens.listServicesOfCategory = null
			state.display.content = null
			state.filters.categories = []
			state.filters.onlyCurrentProjects = false
			state.filters.search = null
			state.filters.statuses = null
			state.loaders.listCategories = false
			state.loaders.listStatuses = false
			state.pagination.descending = false
			state.pagination.page = 1
			state.pagination.rowsPerPage = 15
			state.pagination.sortBy = 'name'
			state.pagination.totalItems = 0
			state.categories = []
			state.statuses = []
		}
	},
	actions: {
		resetFilters: function ({ commit }) {
			return commit('resetFilters')
		},
		setCancelToken: function ({ commit }, data) {
			return commit('setCancelToken', data)
		},
		setCategories: function ({ commit }, categories) {
			return commit('setCategories', categories)
		},
		setContentDisplayMode: function ({ commit }, contentDisplayMode) {
			return commit('setContentDisplayMode', contentDisplayMode)
		},
		setLoader: function ({ commit }, data) {
			return commit('setLoader', data)
		},
		setOnlyCurrentProjects: function ({ commit }, value) {
			return commit('setOnlyCurrentProjects', value)
		},
		setPagination: function ({ commit }, paginationData) {
			return commit('setPagination', paginationData)
		},
		setSearchedCategories: function ({ commit }, categories) {
			return commit('setSearchedCategories', categories)
		},
		setSearchedStatuses: function ({ commit }, statuses) {
			return commit('setSearchedStatuses', statuses)
		},
		setSearchedText: function ({ commit }, search) {
			return commit('setSearchedText', search)
		},
		setStatuses: function ({ commit }, statuses) {
			return commit('setStatuses', statuses)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
