const VideoConference = () => import('@/modules/VideoConference')

export default [
	{
		component: VideoConference,
		meta: {
			context: {}
		},
		name: 'videoconference',
		path: 'videoconference/:context?'
	},
	{
		component: VideoConference,
		meta: {
			client: false,
			context: {
				vendor_id: 'never'
			}
		},
		name: 'videoconference-internal',
		path: 'videoconference/:context?/internal'
	},
	{
		name: 'videoconference-client',
		redirect: '/videoconference/:context',
		path: 'videoconference-client/:context?'
	}
]
