const SuperAdminApplicationsList = () => import('@/views/SuperAdmin/ApplicationsList')

const ApplicationsList = () => import('@/components/SuperAdmin/Applications/ApplicationsList')
const ApplicationForm = () => import('@/components/SuperAdmin/Applications/ApplicationForm')
const SubscriptionsList = () => import('@/components/SuperAdmin/Applications/SubscriptionsList')

export default [
	{
		component: SuperAdminApplicationsList,
		name: 'sa-applications',
		path: 'applications',
		redirect: { name: 'sa-applications-list' },
		children: [
			{
				component: ApplicationsList,
				name: 'sa-applications-list',
				path: '',
				props: route => {
					return {
						page: route.query?.page ? parseInt(route.query.page) : 1,
						rows: route.query?.rows ? parseInt(route.query.rows) : 10,
						search: route.query?.search
					}
				}
			},
			{
				component: SubscriptionsList,
				name: 'sa-applications-subscriptions',
				path: 'subscriptions'
			},
			{
				component: ApplicationForm,
				name: 'sa-applications-form',
				path: ':applicationId',
				props: route => {
					return {
						value: route.params?.applicationId == 'create' ? null : route.params?.applicationId
					}
				}
			}
		]
	}
]
