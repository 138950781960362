import { Axios } from '@/plugins/axios'
import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'
import { i18n } from '@/plugins/i18n'

import BadRequestException from '@/exceptions/BadRequestException'
import ForbiddenException from '@/exceptions/ForbiddenException'
import GlobalException from '@/exceptions/GlobalException'
import NotFoundException from '@/exceptions/NotFoundException'
import NotImplementedException from '@/exceptions/NotImplementedException'
import RequestTimeoutException from '@/exceptions/RequestTimeoutException'
import ServerErrorException from '@/exceptions/ServerErrorException'
import TooManyRequestException from '@/exceptions/TooManyRequestException'
import UnauthorizedException from '@/exceptions/UnauthorizedException'
import UnprocessableEntityException from '@/exceptions/UnprocessableEntityException'

const getErrorMessage = (data) => {
	let result = null
	// TODO: We may have to add a prefix on the errorCode
	if (data?.error?.message) {
		result = data.error.message
	} else if (data?.code) {
		if (i18n.t(data.code)) {
			result = i18n.t(data.code)
		} else if (data?.message) {
			result = data.message
		}
	} else if (data?.message) {
		result = data.message
	}
	return result
}

const handleClientError = (status, data) => {
	let result = null
	let message = getErrorMessage(data)
	switch (status) {
		case 400:
			result = new BadRequestException(message)
			break
		case 401:
			result = new UnauthorizedException(message)
			//AppEventBus.emit(AppEvents.LOGOUT)
			break
		case 403:
			result = new ForbiddenException(message)
			break
		case 404:
			result = new NotFoundException(message)
			break
		case 408:
			result = new RequestTimeoutException(message)
			break
		case 422: {
			if (data && data.errors) {
				message = Object.keys(data.errors)[0] && data.errors[Object.keys(data.errors)[0]] ? data.errors[Object.keys(data.errors)[0]][0] : null
				result = new UnprocessableEntityException(message, data.errors)
			}
			break
		}
		case 429:
			result = new TooManyRequestException()
			break
		default:
			result = new GlobalException(message)
			break
	}
	return result
}

const handleServerError = (status, data) => {
	let message = null
	let result = null

	// TODO: We may have to add a prefix on the errorCode
	if (data?.error?.code && i18n.te(data.error.code)) {
		message = i18n.t(data.error.code)
	} else if (data?.error?.message) {
		message = data.error.message
	} else if (data?.message) {
		message = data.message
	}

	if (status) {
		switch (status) {
			case 500:
				if (!message || message.toLowerCase() == "server error") {
					result = new ServerErrorException()
				} else {
					result = new ServerErrorException(message)
				}

				break
			case 501:
				result = new NotImplementedException(message)
				break
			default:
				result = new GlobalException(message)
				break
		}
	}
	return result
}

/**
 * @param {Number} status
 * @param {Object} data
 */
const handleResponse = (status, data) => {
	let exception = null
	let result = null

	if (status) {
		if (status >= 100 && status < 200) {
			// Informational
		} else if (status >= 200 && status < 300) {
			// Success
		} else if (status >= 300 && status < 400) {
			// Redirection
		} else if (status >= 400 && status < 500) {
			exception = handleClientError(status, data)
		} else if (status >= 500 && status < 600) {
			exception = handleServerError(status, data)
		} else {
			exception = new GlobalException()
		}
	}

	if (exception) {
		throw exception
	}
	return result
}

const onRequestError = error => {
	if (!Axios.isCancel(error)) {
		throw new GlobalException()
	}
	return Promise.reject(error)
}

const onRequestSuccess = config => {
	config.headers.Accept = 'application/json'

	const accessToken = localStorage.getItem('token')
	if (accessToken && (!config.hasOwnProperty('forbiddenHeaders') || !config.forbiddenHeaders.includes('Authorization'))) {
		config.headers.Authorization = accessToken
	}

	const oauthProvider = localStorage.getItem('OAuthProvider')
	if (oauthProvider && (!config.hasOwnProperty('forbiddenHeaders') || !config.forbiddenHeaders.includes('OAuthProvider'))) {
		config.headers.OAuthProvider = oauthProvider
	}

	return config
}

const extractResponseErrorHttpData = error => {
	let config = null
	let httpData = null
	let httpStatus = null
	if (!Axios.isCancel(error) && error && error.response) {
		config = error.response.config
		httpData = error.response.data
		httpStatus = error.response.status
	} else if (!Axios.isCancel(error) && error) {
		config = error.config
		httpData = error.error
		httpStatus = error.status
	}
	return {
		config: config,
		httpData: httpData,
		httpStatus: httpStatus
	}
}

const onResponseError = error => {
	let result = null
	const { config, httpData, httpStatus } = extractResponseErrorHttpData(error)
	try {
		if (httpStatus) {
			if (config?.show_error !== false && config?.params?.show_error !== false) {
				if (httpStatus === "resumable") {
					AppEventBus.emit(AppEvents.SNACKBAR_ERROR, httpData)
					result = Promise.reject(error)
				} else {
					result = handleResponse(httpStatus, httpData)
				}
			} else {
				result = Promise.reject(error)
			}
		} else if (Axios.isCancel(error)) {
			result = Promise.reject(error)
		}
	} catch (exception) {
		if (exception.message && !exception.message.includes(i18n.t('errors.global_error'))) {
			AppEventBus.emit(AppEvents.SNACKBAR_ERROR, exception.message)
		}
		result = Promise.reject(exception)
	}
	return result
}

const onResponseSuccess = async response => {
	if (response?.config?.cache && 'caches' in self) {
		const cache = await caches.open('welyb-cache')
		cache.put(response.request, new Response(response.data, { headers: response.headers, status: response.status }))
	}
	return response
}

export default {
	onRequestError: onRequestError,
	onRequestSuccess: onRequestSuccess,
	onResponseError: onResponseError,
	onResponseSuccess: onResponseSuccess
}
