// ============================================================================
// AuthService
// -----------
// Auth module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/AuthApi'

import { store as STORE } from '@/store'

import AppService from '@/services/AppService'

const Private = {
	setAuthStoreData: function (data) {
		return Promise.all([
			STORE.dispatch('auth/setToken', data?.token),
			STORE.dispatch('auth/setIsAuthenticated', data?.auth),
			STORE.dispatch('auth/setIsSuperAdmin', data?.isAdmin),
			STORE.dispatch('auth/setIsAccountant', data?.isAccountant)
		])
	},
	getIsAuthenticated: function () {
		return STORE.state.auth.isAuthenticated
	},
	setToken: function (token) {
		return STORE.dispatch('auth/setToken', token)
	}
}
// -------
// Exports
// -------
export default {
	setToken: Private.setToken,
	autoLogin: function (refreshToken = true, isMobileApp = false) {
		if (Private.getIsAuthenticated()) {
			return Promise.reject('AuthError : Cannot try autoLogin when a user is already logged')
		}

		const token = localStorage.getItem('token')
		let result
		if (token) {
			result = Private.setToken(token)
				.then(() => {
					if (refreshToken) {
						return API.autoLogin({ show_error: false })
					} else {
						let params = {}
						if (isMobileApp) {
							params = {filters : {is_mobile_app: true}}
						} 
						return API.retrieveLogin({ show_error: false, params })
					}
				})
				.then(results => {
					return Private.setAuthStoreData(results.data)
				})
		} else {
			result = Promise.reject('AuthError: No token was found')
		}

		result = result.catch(error => {
			return STORE.dispatch('auth/reset').then(() => {
				throw error
			})
		})

		return result
	},
	login: function (email, password) {
		return API.login({ email, password }).then(res => Private.setAuthStoreData(res?.data))
	},
	logout: function () {
		return API.logout().finally(() => {
			return STORE.dispatch('reset').finally(() => {
				AppService.goTo('signin')
			})
		})
	},
	getIsAuthenticated: Private.getIsAuthenticated,
	getIsAccountant: function () {
		return STORE.state.auth.isAccountant
	},
	getIsSuperAdmin: function () {
		return STORE.state.auth.isSuperAdmin
	},
	resetAuthentication: function () {
		return STORE.dispatch('auth/reset')
	}
}
