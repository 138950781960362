import autologin from '@/router/routes/noAuth/autologin'
import cgucgv from '@/router/routes/noAuth/cgu-cgv'
import oauth from '@/router/routes/noAuth/oauth'
import password from '@/router/routes/noAuth/password'
import signin from '@/router/routes/noAuth/signin'
import SignInSSOError from '@/router/routes/noAuth/signin-sso-error'

const AnonymousPages = () => import('@/views/AnonymousPages')

export default [
	{
		component: AnonymousPages,
		meta: {
			anonymity_required: true,
			historize: false
		},
		name: 'noAuth',
		path: '/',
		redirect: { name: 'signin' },
		children: [...autologin, ...cgucgv, ...oauth, ...password, ...signin, ...SignInSSOError]
	}
]
