const QuickFolders = () => import('@/modules/QuickFolders')

export default [
	{
		component: QuickFolders,
		meta: {
			accountant: false
		},
		name: 'quickfolders',
		path: 'quickfolders/:context?'
	}
]
