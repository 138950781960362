// ============================================================================
// VueX - Group
// ----------------
// GroupManager VueX store module

// ============================================================================
export default {
	namespaced: true,
	state: {
		list: [],
		selected: null
	},
	mutations: {
		addItemInList: function (state, item) {
			state.list.push(item)
		},
		removeItemFromList: function (state, item) {
			const index = state.list.findIndex(af => af.id === item.id)
			state.list.splice(index, 1)
		},
		setList: function (state, data) {
			state.list = data
		},
		setSelected: function (state, data) {
			state.selected = data
		},
		updateItemFromList: function (state, item) {
			const index = state.list.findIndex(af => af.id === item.id)
			Object.assign(state.list[index], item)
		},
		reset: function (state) {
			state.list = []
			state.selected = null
		}
	},
	actions: {
		addAccountingFirm: function ({ commit }, item) {
			return commit('addItemInList', item)
		},
		deleteAccountingFirm: function ({ commit }, item) {
			return commit('removeItemFromList', item)
		},
		setList: function ({ commit }, list) {
			return commit('setList', list)
		},
		setSelected: function ({ commit }, selected) {
			return commit('setSelected', selected)
		},
		updateAccountingFirm: function ({ commit }, item) {
			return commit('updateItemFromList', item)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
