const SuperAdminNPSSurveyManager = () => import('@/modules/SuperAdminNPSSurveyManager')

export default [
	{
		component: SuperAdminNPSSurveyManager,
		name: 'sa-nps-survey',
		path: 'nps-survey',
		props: false
	}
]
