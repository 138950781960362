import { Config } from '@/services/ConfigService'

let LastMatomoUrl = null

export default function (to) {
	if (['master'].includes(Config.VUE_APP_MODE)) {
		let matomoUrl = to.path
		for (let param in to.params) {
			if (to.params[param]) {
				const staPos = matomoUrl.indexOf('/' + to.params[param])
				if (staPos != -1) {
					const endPos = staPos + to.params[param].length + 1
					const startString = matomoUrl.substring(0, staPos)
					const endString = matomoUrl.substring(endPos)
					matomoUrl = startString + endString
				}
			}
		}
		if (LastMatomoUrl == null || matomoUrl != LastMatomoUrl) {
			window._paq.push(['setCustomUrl', matomoUrl])
			LastMatomoUrl = matomoUrl
		}
	}
	return true
}
