const PasswordCreate = () => import('@/views/PasswordCreate')
const ResetPassword = () => import('@/views/PasswordReset')
const ResetPasswordSubmit = () => import('@/views/PasswordResetSubmit')

export default [
	{
		component: PasswordCreate,
		name: 'create-password',
		path: 'create-password/:token'
	},
	{
		component: ResetPassword,
		name: 'reset-password',
		path: 'reset-password'
	},
	{
		component: ResetPasswordSubmit,
		name: 'reset-password-submit',
		path: 'reset-password-submit/:token'
	}
]
