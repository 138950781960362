// ============================================================================
// VueX - AccountingFirm
// ---------------------
// AccountingFirm VueX store module
// ============================================================================

export default {
	// Always namespaced
	namespaced: true,
	// Module's states
	state: {
		list: null,
		selected: null,
		data: {},
		applications: [],
		isActive: []
	},
	// States mutations
	mutations: {
		addAccountingFirm: function (state, accountingFirm) {
			state.list.push(accountingFirm)
		},
		removeAccountingFirm: function (state, accountingFirm) {
			state.list = state.list.filter(item => item.id !== accountingFirm.id)
		},
		updateAccountingFirm: function (state, accountingFirm) {
			const index = state.list.findIndex(af => af.id == accountingFirm.id)
			Object.assign(state.list[index], accountingFirm)
		},
		setList: function (state, val) {
			state.list = val
		},
		setSelected: function (state, val) {
			state.selected = val
		},
		updateSelected: function (state, val) {
			Object.assign(state.selected, val)
		},
		reset: function (state) {
			state.list = null
			state.selected = null
			state.data = {}
			state.applications = []
			state.isActive = []
		}
	},
	// Module's actions
	actions: {
		addAccountingFirm: function ({ commit }, accountingFirm) {
			return commit('addAccountingFirm', accountingFirm)
		},
		removeAccountingFirm: function ({ commit }, accountingFirm) {
			return commit('removeAccountingFirm', accountingFirm)
		},
		updateAccountingFirm: function ({ commit }, accountingFirm) {
			return commit('updateAccountingFirm', accountingFirm)
		},
		setAccountingFirms: function ({ commit }, accountingFirms) {
			return commit('setList', accountingFirms)
		},
		setAccountingFirm: function ({ commit }, accountingFirm) {
			return commit('setSelected', accountingFirm)
		},
		updateSelectedAccountingFirm: function ({ commit }, accountingFirm) {
			return commit('updateSelected', accountingFirm)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
