// ============================================================================
// VueX - Signature
// ----------------
// Signature VueX store module
// ============================================================================
export default {
	namespaced: true,
	state: {
		list: []
	},
	mutations: {
		addSignatureArea: function (state, signatureArea) {
			state.list.push(signatureArea)
		},
		deleteSignatureArea: function (state, signatureAreaId) {
			const index = state.list.findIndex(signatureArea => signatureArea.id === signatureAreaId)
			if (index >= 0) {
				state.list.splice(index, 1)
			}
		},
		deleteSignatureAreas: function (state) {
			state.list.clear()
		},
		updateSignatureArea: function (state, data) {
			const index = state.list.findIndex(signatureArea => signatureArea.id == data.id)
			if (index >= 0) {
				Object.assign(state.list[index], data)
			}
		},
		reset: function (state) {
			state.list = []
		}
	},
	actions: {
		addSignatureArea: function ({ commit }, signatureArea) {
			return commit('addSignatureArea', signatureArea)
		},
		deleteSignatureArea: function ({ commit }, signatureAreaId) {
			return commit('deleteSignatureArea', signatureAreaId)
		},
		deleteSignatureAreas: function ({ commit }) {
			return commit('deleteSignatureAreas')
		},
		updateSignatureArea: function ({ commit }, signatureArea) {
			return commit('updateSignatureArea', signatureArea)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
