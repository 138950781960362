import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api')

const changePassword = data => {
	return axios.post('/change_password', data)
}

const createPassword = data => {
	return axios.post('/passwords', data)
}

const getAccountingFirm = () => {
	return axios.get('/users/me/accounting-firms', { params: { active_domain: true, accountant: true } })
}

const getUserAccountingFirms = () => {
	return axios.get('/users/me/accounting-firms', { params: { active_domain: true } })
}

const getAccountingFirmUserCompanyList = accountingFirmId => {
	const url = `/users/me/accounting-firms/${accountingFirmId}/vendors`
	return axios.get(url)
}

const getPasswordReset = token => {
	return axios.get(`/password-resets?token=${token}`)
}

const getUserData = () => {
	return axios.get('/users/me')
}

const loadCompanyUserRole = vendorId => {
	const url = `/vendors/${vendorId}/roles`
	return axios.get(url)
}

const resetPasswordSendMail = data => {
	return axios.put('/password-resets', data)
}

const resetPasswordSubmit = data => {
	return axios.put('/passwords', data)
}

const getHubspotToken = data => {
	return axios.post('/hubspot', data)
}

const getModuleAccess = (moduleName, accountingFirmId, vendorId) => {
	const url = `/modules/${accountingFirmId}/companies/${vendorId}/${moduleName}`
	return axios.get(url)
}

const getModulesAsAccountant = (accountingFirmId, params) => {
	const url = `/modules/${accountingFirmId}`
	return axios.get(url, { params: { filters: params }  })
}

const getModulesAsCompanyUser = (accountingFirmId, vendorId, params) => {
	const url = `/modules/${accountingFirmId}/companies/${vendorId}`
	return axios.get(url, { params : {filters: params}  })
}

export default {
	changePassword: changePassword,
	createPassword: createPassword,
	getAccountingFirm: getAccountingFirm,
	getUserAccountingFirms: getUserAccountingFirms,
	getAccountingFirmUserCompanyList: getAccountingFirmUserCompanyList,
	getPasswordReset: getPasswordReset,
	getUserData: getUserData,
	loadCompanyUserRole: loadCompanyUserRole,
	resetPasswordSendMail: resetPasswordSendMail,
	resetPasswordSubmit: resetPasswordSubmit,
	getHubspotToken: getHubspotToken,
	getModuleAccess: getModuleAccess,
	getModulesAsAccountant: getModulesAsAccountant,
	getModulesAsCompanyUser: getModulesAsCompanyUser
}
