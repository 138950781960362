export default {
	namespaced: true,
	state: {
		data: [],
		linkedToAccountingFirmAndUser: []
	},
	mutations: {
		set (state, val) {
			state.data = val
		},
		setLinked (state, val) {
			state.linkedToAccountingFirmAndUser = val
		},
		reset: function (state) {
			state.data = []
			state.linkedToAccountingFirmAndUser = []
		}
	},
	actions: {
		set: function ({ commit }, data) {
			return commit('set', data)
		},
		setClientsLinked: function ({ commit }, data) {
			return commit('setLinked', data)
		},
		reset: function ({ commit }) {
			commit('reset')
		}
	}
}
