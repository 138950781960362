const SSOGoTo = () => import('@/views/SSOGoTo')

export default [
	{
		component: SSOGoTo,
		meta: {
			historize: false
		},
		name: 'go-to',
		path: 'sso/:context?'
	}
]
