export default {
	computed: {
		appEventsActionsMapping: function () {
			let result = []
			if (this.getAppEventsActionsMapping != undefined && Array.isArray(this.getAppEventsActionsMapping())) {
				result = this.getAppEventsActionsMapping()
			}
			return result
		},
		moduleEventsActionsMapping: function () {
			let result = []
			if (this.getModuleEventsActionsMapping != undefined && Array.isArray(this.getModuleEventsActionsMapping())) {
				result = this.getModuleEventsActionsMapping()
			}
			return result
		},
		isSingleInstanceListening: function () {
			let result = false
			if (this.isSingleInstanceEventListener != undefined) {
				result = this.isSingleInstanceEventListener()
			}
			return result
		}
	},
	mounted: function () {
		if (this.appEventsActionsMapping.length > 0 && this.isRegisteringAllowed(this.appEventBus)) {
			this.appEventBus.initializeListeners(this.appEventsActionsMapping, this)
		}
		if (this.eventBus && this.moduleEventsActionsMapping.length > 0 && this.isRegisteringAllowed(this.eventBus)) {
			this.eventBus.initializeListeners(this.moduleEventsActionsMapping, this)
		}
	},
	destroyed: function () {
		if (this.appEventsActionsMapping.length > 0 && this.isRegisteringAllowed(this.appEventBus)) {
			this.appEventBus.releaseListeners(this)
		}
		if (this.eventBus && this.moduleEventsActionsMapping.length > 0 && this.isRegisteringAllowed(this.eventBus)) {
			this.eventBus.releaseListeners(this)
		}
	},
	methods: {
		isRegisteringAllowed: function (eventBus) {
			let result = true
			if (
				this.isSingleInstanceListening &&
				eventBus.registeredComponents &&
				Array.isArray(eventBus.registeredComponents) &&
				eventBus.registeredComponents[this.$options._componentTag]
			) {
				result = eventBus.registeredComponents[this.$options._componentTag].length == 0
			}
			return result
		},
		getAppEventsActionsMapping: function () {
			return []
		},
		getModuleEventsActionsMapping: function () {
			return []
		},
		isSingleInstanceEventListener: function () {
			return false
		}
	}
}
