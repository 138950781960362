import { router as ROUTER } from '@/router'

const isMobileApp = function (to, from) {
	let result = false
	if ((from.query.embedded && ['mobileapp', 'genericmobileapp'].includes(from.query.embedded))
			|| (to.query.embedded && ['mobileapp', 'genericmobileapp'].includes(to.query.embedded))) {
			result = true
		}
	return result
}

const getToken = function (to, from) {
	let result
	if (isMobileApp(to, from) && from.query.token) {
		result = from.query.token
	} else if (isMobileApp(to, from) && to.query.token) {
		result = to.query.token
	} else {
		result = localStorage.getItem('token')
	}
	return result
}

const doRefreshToken = function (to, from) {
	let result = true
	const meta = ROUTER.getMeta(to)
	if (meta.refresh_token === false || (isMobileApp(to, from) && (from.query.token || to.query.token))) {
		result = false
	}
	return result
}

export default function (to, from) {
	let result = true

	const toMeta = ROUTER.getMeta(to)

	if ((!from || from.fullPath == '/') && (!toMeta.hasOwnProperty('auto_login') || toMeta.auto_login)) {
		const token = getToken(to, from)
		if (token) {
			result = {
				name: 'autologin',
				query: {
					token
				}
			}
			result.query.refresh_token = doRefreshToken(to, from)
		} else if (
			to.matched.some(record => {
				const meta = ROUTER.getMeta(record)
				return meta.authentication_required
			})
		) {
			result = {
				name: 'signin',
				query: {}
			}
		}

		if (
			result !== true &&
			to.matched.some(record => {
				const meta = ROUTER.getMeta(record)
				return meta.authentication_required
			}) &&
			window.location.pathname != '/'
		) {
			result.query.redirect = encodeURIComponent(window.location.pathname + window.location.search)
		}
	}

	return result
}
