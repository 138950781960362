import AnonymityNavigationGuard from '@/router/guards/AnonymityNavigationGuard'
import AuthenticationNavigationGuard from '@/router/guards/AuthenticationNavigationGuard'
import AutoLoginNavigationGuard from '@/router/guards/AutoLoginNavigationGuard'
import ContextNavigationGuard from '@/router/guards/ContextNavigationGuard'
import HistoryNavigationGuard from '@/router/guards/HistoryNavigationGuard'
import SuperAdminNavigationGuard from '@/router/guards/SuperAdminNavigationGuard'
import UserTypeNavigationGuard from '@/router/guards/UserTypeNavigationGuard'
import EmbeddedNavigationGuard from '@/router/guards/EmbeddedNavigationGuard'
import SSOGuard from '@/router/guards/SSOGuard'

const GUARDS = []

GUARDS.push(AutoLoginNavigationGuard)
GUARDS.push(AuthenticationNavigationGuard)
GUARDS.push(SSOGuard)
GUARDS.push(AnonymityNavigationGuard)
GUARDS.push(SuperAdminNavigationGuard)
GUARDS.push(UserTypeNavigationGuard)
GUARDS.push(ContextNavigationGuard)
GUARDS.push(HistoryNavigationGuard)
GUARDS.push(EmbeddedNavigationGuard)


let lastFullPath

export default function (to, from) {
	let result = true
	for (const guard of GUARDS) {
		const guardResult = guard(to, from, lastFullPath)
		if (guardResult !== true) {
			result = guardResult
			break
		}
	}

	if (typeof result == 'string') {
		lastFullPath = result
	} else if (result === true) {
		lastFullPath = to.fullPath
	} else if (typeof result == 'object' && result !== null) {
		lastFullPath = result.fullPath
	}

	return result
}
