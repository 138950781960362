/* eslint-disable no-console */
import { register } from 'register-service-worker'

register(`/service-worker.js`, {
	ready: function () {},
	registered: function (registration) {
		setInterval(
			function () {
				registration.update()
			}.bind(registration),
			1000 * 60 * 5
		)
		if (registration.waiting && registration.active) {
			window.swNeedUpdate = true;
		}
	},
	cached: function () {},
	updatefound: function (registration) {
		const newRegistration = registration
		const newServiceWorker = newRegistration.installing
		newServiceWorker.onstatechange = () => {
			if (newServiceWorker.state === 'installed') {
				window.swNeedUpdate = true;
				document.dispatchEvent(new CustomEvent('newServiceWorkerWaiting', { detail: newRegistration }))
			}
		}
		if (newRegistration.waiting) {
			document.dispatchEvent(new CustomEvent('newServiceWorkerWaiting', { detail: newRegistration }))
		}
	},
	updated: function (registration) {
		if (registration.waiting) {
			document.dispatchEvent(new CustomEvent('newServiceWorkerWaiting', { detail: registration }))
		}
	},
	offline: function () {},
	error: function () {}
})
