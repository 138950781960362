export default {
	namespaced: true,
	state: {
		editedUser: null,
	},
	mutations: {
		setEditedUser (state, editedUser) {
			state.editedUser = editedUser
		},
		setTotalHoldingUserVendor (state, totalHoldingUserVendor) {
			state.editedUser.totalHoldingUserVendor = totalHoldingUserVendor
		},
		stateTotalHoldingVendorLinkedWithUser (state, totalHoldingVendorLinkedWithUser) {
			state.editedUser.totalHoldingVendorLinkedWithUser = totalHoldingVendorLinkedWithUser
		},
		reset (state) {
			state.editedUser = null
		}
	},
	actions: {
		setEditedUser: function ({ commit }, data) {
			return commit('setEditedUser', data)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	},
	getters: {
		getEditedUser: function (state) {
			return state.editedUser
		},
		userHasAccessToAllVendors: function (state) {
			return state.editedUser.totalHoldingVendorLinkedWithUser === state.editedUser.totalHoldingUserVendor
		}
	}
}
