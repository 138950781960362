import { router as ROUTER } from '@/router'

const getContextSettings = to => {
	let result = {}
	for (let i = to.matched.length - 1; i >= 0; i--) {
		const route = to.matched[i]
		const meta = ROUTER.getMeta(route)
		if (meta.hasOwnProperty('context')) {
			result = meta.context
			break
		}
	}

	if (!result.hasOwnProperty('accounting_firm_id')) {
		result.accounting_firm_id = 'always'
	}
	if (!result.hasOwnProperty('vendor_id')) {
		result.vendor_id = 'always'
	}
	return result
}

const computeContext = (to, from) => {
	let { accounting_firm_id: accountingFirmId, vendor_id: vendorId } = ROUTER.decryptContext(to.params.context)
	const settings = getContextSettings(to)

	if (settings.accounting_firm_id == 'never') {
		accountingFirmId = null
	}
	if (settings.vendor_id == 'never' || (settings.vendor_id == 'sometimes' && from.name != to.name)) {
		vendorId = null
	}

	return ROUTER.encryptContext(accountingFirmId, vendorId)
}

const isContextRequired = route => {
	return route.matched.some(item => item.path.includes(':context'))
}

const isContextValid = route => {
	const { accounting_firm_id: accountingFirmId, vendor_id: vendorId } = ROUTER.decryptContext(route.params.context)
	const settings = getContextSettings(route)

	return (
		((settings.accounting_firm_id == 'always' && accountingFirmId) ||
			settings.accounting_firm_id == 'sometimes' ||
			(settings.accounting_firm_id == 'never' && !accountingFirmId)) &&
		((settings.vendor_id == 'always' && vendorId) || settings.vendor_id == 'sometimes' || (settings.vendor_id == 'never' && !vendorId))
	)
}

export default function (to, from, lastFullPath) {
	let result = true

	if (isContextRequired(to) && lastFullPath != to.fullPath) {
		const context = computeContext(to, from)

		if (context != to.params.context) {
			const route = ROUTER.resolve({
				name: to.name,
				params: {
					...to.params,
					context
				},
				query: to.query,
				replace: true
			}).route
			if (route.fullPath == from.fullPath) {
				result = false
			} else if (route.fullPath != to.fullPath && route.fullPath != lastFullPath) {
				result = route
			}
		}

		if (isContextRequired(from) && !isContextValid(from)) {
			result = {
				...to,
				replace: true
			}
		}
	}

	return result
}
