import Vue from 'vue'
import Vuex from 'vuex'

import accountingFirm from '@/store/modules/accountingFirm'
import accountingTeam from '@/store/modules/accountingTeam'
import auth from '@/store/modules/auth'
import applications from '@/store/modules/applications'
import chat from '@/store/modules/chat'
import clients from '@/store/modules/clients'
import company from '@/store/modules/company'
import customers from '@/store/modules/customers'
import dialogs from '@/store/modules/dialogs'
import documents from '@/store/modules/documents'
import globalParameter from '@/store/modules/globalParameter'
import group from '@/store/modules/group'
import mobileapps from '@/store/modules/mobileapps'
import modules from '@/store/modules/modules'
import holdingUsers from '@/store/modules/holdingUsers'
import notifications from '@/store/modules/notifications'
import oauthProviders from '@/store/modules/oauthProviders'
import offers from '@/store/modules/offers'
import sideBar from "@/store/modules/sideBar";
import signature from '@/store/modules/signature'
import tasks from '@/store/modules/tasks'
import teamManager from '@/store/modules/teamManager'
import themes from '@/store/modules/themes'
import user from '@/store/modules/user'
import whitelabel from '@/store/modules/whitelabel'
import workflows from '@/store/modules/workflows'

import * as Files from '@/helpers/files'

Vue.use(Vuex)

const subModules = {
	accountingFirm,
	accountingTeam,
	auth,
	applications,
	chat,
	clients,
	company,
	customers,
	documents,
	dialogs,
	group,
	globalParameter,
	holdingUsers,
	mobileapps,
	modules,
	notifications,
	oauthProviders,
	offers,
	sideBar,
	signature,
	themes,
	tasks,
	teamManager,
	user,
	whitelabel,
	workflows,
}

const readWriteStates = {
	isNewVersionAvailable: false,
	loadingDialog: false,
	pauseKeyboardListening: false,
	preventDefaultKeyboardEvents: true,
}
const readOnlyStates = {
	appVersion: process.env.FRONTEND_VERSION || '0',
	imageAccepts: [
		Files.mimetypes.gif,
		Files.mimetypes.jpeg,
		Files.mimetypes.pjpeg,
		Files.mimetypes.svg,
		Files.mimetypes.png,
		Files.mimetypes.xpng,
		Files.mimetypes.webp
	].join(','),
	imageExtensions: 'gif,jpeg,jpg,png,webp',
	rules: {
		required: val => !!val || 'Required.',
		color: val => {
			const pattern = /^(#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{6})$/
			return pattern.test(val)
		}
	}
}
const states = { ...readOnlyStates, ...readWriteStates }

const mutations = {
	setIsNewVersionAvailable (state, isNewVersionAvailable) {
		state.isNewVersionAvailable = isNewVersionAvailable
	},
	setLoadingDialog (state, loadingDialog) {
		state.loadingDialog = loadingDialog
	},
	setPauseKeyboardListening (state, pauseKeyboardListening) {
		state.pauseKeyboardListening = pauseKeyboardListening
	},
	setPreventDefaultKeyboardEvents (state, preventDefaultKeyboardEvents) {
		state.preventDefaultKeyboardEvents = preventDefaultKeyboardEvents
	}
}

const actions = {
	setIsNewVersionAvailable ({ commit }, status) {
		return commit('setIsNewVersionAvailable', status)
	},
	setLoadingDialog ({ commit }, status) {
		return commit('setLoadingDialog', status)
	},
	setPauseKeyboardListening ({ commit }, pauseKeyboardListening) {
		return commit('setPauseKeyboardListening', pauseKeyboardListening)
	},
	setPreventDefaultKeyboardEvents ({ commit }, preventDefaultKeyboardEvents) {
		return commit('setPreventDefaultKeyboardEvents', preventDefaultKeyboardEvents)
	},
	reset: function ({ dispatch }) {
		return Promise.all([
			Object.keys(subModules)
				.filter(subModuleName => typeof subModules[subModuleName]?.actions?.reset === 'function')
				.map(subModuleName => dispatch(`${subModuleName}/reset`))
		]);
	}
}

export const store = new Vuex.Store({ modules: subModules, state: states, mutations: mutations, actions: actions })
