import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/plugins/i18n/messages'
import dateTimeFormats from '@/plugins/i18n/dateTimeFormats'
import numberFormats from '@/plugins/i18n/numberFormats'

Vue.use(VueI18n)

const AVAILABLE_LOCALS = ['en', 'fr', 'pt']

const locales = navigator.languages
let locale = 'fr'

if (locales && locales.length > 0) {
	for (let i = 0; i < locales.length; i++) {
		let loc = locales[i]
		if (loc.includes('-')) {
			const index = loc.indexOf('-')
			loc = loc.substring(0, index)
		}
		if (AVAILABLE_LOCALS.includes(loc)) {
			locale = loc
			break
		}
	}
}

export const i18n = new VueI18n({
	fallbackLocale: 'fr',
	locale: locale,
	messages: messages,
	dateTimeFormats: dateTimeFormats,
	numberFormats: numberFormats,
	silentTranslationWarn: true
})
