import authenticated from '@/router/routes/authenticated'
import anonymous from '@/router/routes/anonymous'
import unknownDomain from '@/router/routes/unknownDomain'

export default [
	...authenticated,
	...anonymous,
	...unknownDomain,
	{
		path: '*',
		redirect: '/'
	}
]
