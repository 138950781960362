const ServiceRequests = () => import('@/modules/ServiceRequests')
const ServiceRequestsCatalog = () => import('@/components/ServiceRequests/ACatalog')
const ServiceRequestsCatalogServiceDetail = () => import('@/components/ServiceRequests/CatalogServiceDetail')
const ServiceRequestsCatalogOfferDetail = () => import('@/components/ServiceRequests/CatalogOfferDetail')

const Helper = {
	getRouteCatalogOffer: function (route) {
		return route.query['catalog-offer']
	},
	getRouteCatalogService: function (route) {
		return route.query['catalog-service']
	}
}

export default [
	{
		component: ServiceRequests,
		meta: {
			accountant: false
		},
		name: 'service-requests',
		path: 'service-requests/:context?',
		props: route => {
			let filters = { categories: [] }
			if (route.query.categories) {
				filters.categories = route.query.categories + ''.split(',').map(c => parseInt(c + ''.trim(), 16))
			}

			return {
				filters
			}
		},
		redirect: { name: 'service-requests-catalog' },

		children: [
			{
				component: ServiceRequestsCatalog,
				name: 'service-requests-catalog',
				path: 'catalog',
				props: route => {
					let catalogOfferIds = []
					if (Helper.getRouteCatalogOffer(route)) {
						catalogOfferIds = (Helper.getRouteCatalogOffer(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
					}

					let catalogServiceIds = []
					if (Helper.getRouteCatalogService(route)) {
						catalogServiceIds = (Helper.getRouteCatalogService(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
					}

					let filters = { categories: [] }
					if (route.query.categories) {
						filters.categories = (route.query.categories + '').split(',').map(c => parseInt(c + ''.trim(), 16))
					}

					return {
						catalogOfferIds,
						catalogServiceIds,
						filters
					}
				},
				children: [
					{
						component: ServiceRequestsCatalogServiceDetail,
						meta: {
							historize: false
						},
						name: 'service-requests-catalog-service-detail',
						path: 'service/:serviceId?',
						props: route => {
							let serviceId
							if (route.params.serviceId) {
								serviceId = parseInt(route.params.serviceId, 16)
							}

							let catalogOfferIds = []
							if (Helper.getRouteCatalogOffer(route)) {
								catalogOfferIds = (Helper.getRouteCatalogOffer(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							let catalogServiceIds = []
							if (Helper.getRouteCatalogService(route)) {
								catalogServiceIds = (Helper.getRouteCatalogService(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							let filters = { categories: [] }
							if (route.query.categories) {
								filters.categories = (route.query.categories + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							return { serviceId, catalogOfferIds, catalogServiceIds, filters }
						}
					},
					{
						component: ServiceRequestsCatalogOfferDetail,
						meta: {
							historize: false
						},
						name: 'service-requests-catalog-offer-detail',
						path: 'offer/:offerId?',
						props: route => {
							let offerId

							if (route.params.offerId) {
								offerId = parseInt(route.params.offerId, 16)
							}

							let catalogOfferIds = []
							if (Helper.getRouteCatalogOffer(route)) {
								catalogOfferIds = (Helper.getRouteCatalogOffer(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							let catalogServiceIds = []
							if (Helper.getRouteCatalogService(route)) {
								catalogServiceIds = (Helper.getRouteCatalogService(route) + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							let filters = { categories: [] }
							if (route.query.categories) {
								filters.categories = (route.query.categories + '').split(',').map(c => parseInt(c + ''.trim(), 16))
							}

							return { offerId, catalogOfferIds, catalogServiceIds, filters }
						}
					}
				]
			}
		]
	}
]
