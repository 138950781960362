// ============================================================================
// WorkflowManagerService
// ----------------------
// WorkflowManager module related services
// ============================================================================

// -------
// Imports
// -------

import API from '@/apis/WorkflowManagerApi'

// ---------
// Internals
// ---------
const Private = {
	requests: {
		node: {
			workflows: {
				create: API.node.workflows.create,
				read: API.node.workflows.read,
				delete: API.node.workflows.delete
			},
			workflowStatuses: {
				create: API.node.workflowStatuses.create,
				read: API.node.workflowStatuses.read
			}
		},
		vendor: {
			users: {
				read: API.vendor.users.read
			},
			workflow: {
				create: API.vendor.workflow.create,
				delete: API.vendor.workflow.delete,
				read: API.vendor.workflow.read,
				update: API.vendor.workflow.update
			},
			workflows: {
				read: API.vendor.workflows.read
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	listUsers: Private.requests.vendor.users.read,
	createWorkflow: Private.requests.vendor.workflow.create,
	deleteWorkflow: Private.requests.vendor.workflow.delete,
	getWorkflow: Private.requests.vendor.workflow.read,
	listWorkflows: Private.requests.vendor.workflows.read,
	modifyWorkflow: Private.requests.vendor.workflow.update,
	listNodeWorkflows: Private.requests.node.workflows.read,
	createNodeWorkflow: Private.requests.node.workflows.create,
	deleteNodeWorkflow: Private.requests.node.workflows.delete,
	getNodeWorkflow: Private.requests.node.workflows.read,
	createNodeWorkflowStatus: Private.requests.node.workflowStatuses.create,
	getNodeWorkflowStatuses: Private.requests.node.workflowStatuses.read
}
