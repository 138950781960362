import Vue from 'vue'
import { Config } from '@/services/ConfigService'

import VueMatomo from 'vue-matomo'

import { router } from '@/router'

if (Config.VUE_APP_MATOMO_ENABLED) {
	Vue.use(VueMatomo, {
		// Configure your matomo server and site by providing
		host: Config.VUE_APP_MATOMO_URL,
		siteId: Config.VUE_APP_MATOMO_SITE_ID,

		// Changes the default .js and .php endpoint's filename
		// Default: 'matomo'
		//trackerFileName: Config.VUE_APP_MATOMO_TRACKER_FILENAME,

		// Overrides the autogenerated tracker endpoint entirely
		// Default: undefined
		// trackerUrl: 'https://example.com/whatever/endpoint/you/have',
		// trackerUrl: Config.VUE_APP_MATOMO_TRACKER_URL,

		// Overrides the autogenerated tracker script path entirely
		// Default: undefined
		// trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',
		// trackerScriptUrl: Config.VUE_APP_MATOMO_TRACKER_SCRIPT_URL,

		// Enables automatically registering pageviews on the router
		router: router,

		// Enables link tracking on regular links. Note that this won't
		// work for routing links (ie. internal Vue router links)
		// Default: true
		enableLinkTracking: Config.VUE_APP_MATOMO_ENABLE_LINK_TRACKING,

		// Require consent before sending tracking information to matomo
		// Default: false
		requireConsent: Config.VUE_APP_MATOMO_REQUIRE_CONSENT,

		// Whether to track the initial page view
		// Default: true
		trackInitialView: Config.VUE_APP_MATOMO_TRACK_INITIAL_VIEW,

		// Run Matomo without cookies
		// Default: false
		disableCookies: Config.VUE_APP_MATOMO_COOKIES_DISABLE,

		// Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
		// Default: false
		enableHeartBeatTimer: Config.VUE_APP_MATOMO_HEARTBEAT_TIMER_ENABLE,

		// Set the heartbeat timer interval
		// Default: 15
		heartBeatTimerInterval: Config.VUE_APP_MATOMO_HEARTBEAT_TIMER_INTERVAL,

		// Whether or not to log debug information
		// Default: false
		debug: Config.VUE_APP_MATOMO_DEBUG,

		// UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
		// Default: undefined
		/* userId: undefined, */

		// Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
		// Default: undefined, example '*.example.com'
		cookieDomain: window.location.hostname,

		// Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
		// Default: undefined, example: '*.example.com'
		domains: window.location.hostname,

		// A list of pre-initialization actions that run before matomo is loaded
		// Default: []
		// Example: [
		//   ['API_method_name', parameter_list],
		//   ['setCustomVariable','1','VisitorType','Member'],
		//   ['appendToTrackingUrl', 'new_visit=1'],
		//   etc.
		// ]
		preInitActions: []
	})
}
