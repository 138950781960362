const UnknownSubDomain = () => import('@/views/UnknownSubDomain')

export default [
	{
		component: UnknownSubDomain,
		meta: {
			historize: false
		},
		name: 'oops',
		path: '/oops'
	}
]
