export default {
	destroyed: function () {
		this.$el.removeEventListener('wheel', this._zoomCatcher_onWheel)
		window.removeEventListener('keydown', this._zoomCatcher_onKeyDown)
	},
	mounted: function () {
		window.addEventListener('keydown', this._zoomCatcher_onKeyDown)
		this.$el.addEventListener('wheel', this._zoomCatcher_onWheel)
	},
	methods: {
		_zoomCatcher_emitZoomInEvent (event) {
			if (this.hasOwnProperty('onZoomIn')) {
				this.onZoomIn(event)
			}
		},
		_zoomCatcher_emitZoomOutEvent (event) {
			if (this.hasOwnProperty('onZoomOut')) {
				this.onZoomOut(event)
			}
		},
		_zoomCatcher_onKeyDown: function (event) {
			let hasKey = (this.OS.isMacOS && event.metaKey) || ((this.OS.isLinux || this.OS.isWindows) && event.ctrlKey)
			if (!(hasKey && ['0', 'à', '=', '+', '-'].includes(event.key))) {
				return
			}
			event.preventDefault()
			if (event.key === '-') {
				this._zoomCatcher_emitZoomOutEvent(event)
			} else if (['=', '+'].includes(event.key)) {
				this._zoomCatcher_emitZoomInEvent(event)
			} else {
				this._zoomCatcher_emitResetZoomEvent(event)
			}
		},
		_zoomCatcher_onWheel: function (event) {
			if ((!this.OS.isLinux && !this.OS.isWindows) || !event.ctrlKey || !event.deltaY) {
				return
			}
			event.preventDefault()
			if (event.deltaY < 0) {
				this._zoomCatcher_emitZoomInEvent()
			} else {
				this._zoomCatcher_emitZoomOutEvent()
			}
		},
		_zoomCatcher_emitResetZoomEvent: function (event) {
			if (this.hasOwnProperty('onResetZoom')) {
				this.onResetZoom(event)
			}
		}
	}
}
