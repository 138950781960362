import AuthService from '@/services/Auth/AuthService'
import OauthProviderService from '@/services/OauthProvider/OauthProviderService'

export default function (to) {
    if (!AuthService.getIsAuthenticated() && to.query.hasOwnProperty('sso')) {
        const ssoValue = to.query.sso
        OauthProviderService.getOauthProviders().then((oauthProviders) => {
            oauthProviders.forEach(oauthProvider => {
                if (oauthProvider.name == ssoValue) {
                    window.location.href = oauthProvider.link
                }
            })
            return true
        })
    } else {
        return true
    }
	
}
