import { router as ROUTER } from '@/router'
import AuthService from '@/services/Auth/AuthService'

export default function (to, from) {
	let result = true

	const meta = ROUTER.getMeta(to)

	if (meta.super_admin && !AuthService.getIsSuperAdmin()) {
		const fromMeta = ROUTER.getMeta(from)
		if (!fromMeta.authentication_required) {
			result = {
				name: 'home'
			}
		} else {
			result = false
		}
	}

	return result
}
