import { Node } from '@/classes/Documents/Node.class'
import { Document } from '@/classes/Documents/Document.class'
import { Folder } from '@/classes/Documents/Folder.class'
import { Structure } from '@/classes/Documents/Structure.class'

export class NodeFactory {
	static createNode (rawNode) {
		let result
		if (Node.isDocumentNode(rawNode)) {
			result = new Document(rawNode)
		} else if (Node.isFolderNode(rawNode)) {
			if (Node.isRootNode(rawNode)) {
				result = new Structure(rawNode)
			} else {
				result = new Folder(rawNode)
			}
		} else {
			throw new Error('Unknown kind of node')
		}
		return result
	}
}
