const CustomersManager = () => import('@/modules/CustomersManager')

export default [
	{
		component: CustomersManager,
		meta: {
			client: false,
			context: {
				vendor_id: 'never'
			}
		},
		name: 'customers',
		path: 'customers/:context?'
	}
]
