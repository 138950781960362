const AccountingFirmManager = () => import('@/views/SuperAdmin/AccountingFirmManager')

const AccountingFirmForm = () => import('@/components/SuperAdmin/AccountingFirms/AccountingFirmForm')
const AccountingFirmsList = () => import('@/components/SuperAdmin/AccountingFirms/AccountingFirmsList')

export default [
	{
		component: AccountingFirmManager,
		name: 'sa-accounting-firms',
		path: 'accounting-firms',
		redirect: { name: 'sa-accounting-firms-list' },
		children: [
			{
				component: AccountingFirmsList,
				name: 'sa-accounting-firms-list',
				path: ''
			},
			{
				component: AccountingFirmForm,
				meta: {
					historize: false
				},
				name: 'sa-accounting-firms-form',
				path: ':accountingFirmId',
				props: route => {
					return {
						value: route.params?.accountingFirmId == 'create' ? null : route.params?.accountingFirmId
					}
				}
			}
		]
	}
]
