const ModulesManager = () => import('@/components/SuperAdmin/Modules/ModulesManager')
const ModulesSubscriptionsManager = () => import('@/components/SuperAdmin/Modules/ModulesSubscriptionsManager')

export default [
	{
		component: ModulesManager,
		name: 'sa-modules-manager',
		path: 'modules'
	},
	{
		component: ModulesSubscriptionsManager,
		name: 'sa-modules-subscriptions-manager',
		path: 'modules/subscriptions'
	}
]
