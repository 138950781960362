// ============================================================================
// VueX - GlobalParameter
// ----------------
// GlobalParameter VueX store module
// ============================================================================
export default {
	namespaced: true,
	state: {
		list: [],
		ecmAutoRefresh: null
	},
	mutations: {
		addOrUpdateGlobalParameter: function (state, data) {
			const index = state.list.findIndex(gp => gp.key === data.key)
			if (index >= 0) {
				Object.assign(state.list[index], data)
			} else {
				state.list.push(data)
			}
			if (data.key == 'ecmAutoRefresh') {
				state.ecmAutoRefresh = data.value
			}
		}
	},
	actions: {
		addOrUpdateGlobalParameter: function ({ commit }, data) {
			return commit('addOrUpdateGlobalParameter', data)
		},
		reset: function () {}
	}
}
