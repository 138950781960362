import EventEmitter from 'events'
import Resumable from 'resumablejs'
import { v4 as uuid } from 'uuid'
import { Config } from '@/services/ConfigService'
import { getFileExtension } from '@/helpers/files'
import Interceptor from '@/plugins/axios/DefaultInterceptor'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

const MAX_CONCURRENT_UPLOADS = 4
const MAX_RETRIES = 1

class Job {
	// eslint-disable-next-line space-before-function-paren
	constructor(url, file, uploadObject, params = {}) {
		this.url = url
		this.file = file
		this.uploadObject = uploadObject
		this.parameters = { ...params }
		this.parameters.name = this.filename
		this.tries = 0
		this.resumable = new Resumable({
			chunkRetryInterval: Config.VUE_APP_UPLOAD_RETRY_INTERVAL,
			chunkSize: Config.VUE_APP_UPLOAD_CHUNK_SIZE,
			headers: {
				Accept: 'application/json',
				Authorization: localStorage.getItem('token')
			},
			permanentErrors: [400, 401, 403, 404, 415, 422, 500, 501],
			query: { ...this.parameters },
			simultaneousUploads: Config.VUE_APP_UPLOAD_MAX_CONCURRENT_CHUNKS,
			target: this.url,
			testChunks: false
		})
	}

	get filename () {
		if (this.file.name && this.file.name.length >= 191) {
			const splittedName = this.file.name.split('.')
			const extension = splittedName.pop()
			let name = splittedName.join('.').substring(0, 190 - (extension.length + 1))
			return name + '.' + extension
		}
		return this.file.name
	}

	get uploadId () {
		return this.uploadObject.id
	}

	setupFileOriginalName () {
		Object.defineProperty(this.file, 'original_name', {
			value: this.file.name,
			writable: true,
			enumerable: true
		})
	}
	setupFileName () {
		Object.defineProperty(this.file, 'name', {
			value: `${Date.now().toString()}-${Math.floor(Math.random() * 100).toString()}.${getFileExtension(this.file?.name)}`,
			writable: true,
			enumerable: true
		})
	}
	setupResumable (resolve) {
		this.resumable.on('fileAdded', () => {
			this.resumable.upload()
		})
		this.resumable.on('progress', () => {
			const progress = this.resumable.progress()
			this.uploadObject.progression = Math.round(progress * 100)
		})
		this.resumable.on('error', (error, failedFile) => {
			if (this.tries < MAX_RETRIES) {
				this.tries++
				failedFile.retry()
			} else {
				AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)
				const jsonError = JSON.parse(error)
				Interceptor.onResponseError( {
					error: jsonError?.message || jsonError?.errors?.name[0],
					config: {
						show_error: true
					},
					status: "resumable",
				}).catch(() => {
					// Catch is necessary but do nothing, error already handled
				});
			}
		})
		this.resumable.on('fileSuccess', successedFile => {
			let data = {}
			if (successedFile?.chunks) {
				for (let i = 0; i < successedFile.chunks.length; i++) {
					const chunk = successedFile.chunks[i]
					const chunkData = JSON.parse(chunk.message())
					if (chunkData?.data?.id && !chunk?.data?.done) {
						data = chunkData
						break
					}
				}
			}
			AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)
			resolve(data, successedFile)
		})
	}

	run () {
		let result = Promise.resolve()
		if (this.file.size > 0) {
			this.setupFileOriginalName()
			this.setupFileName()
			result = new Promise(this.setupResumable.bind(this))
			this.resumable.addFile(this.file)
		} else {
			AppEventBus.emit(AppEvents.SNACKBAR_WARNING, window.vueInstance.$t('documents.errors.upload_is_empty'))
			AppEventBus.emit(AppEvents.REMOVE_UPLOAD_PROGRESS, this.uploadObject.id)
		}
		return result
	}
}

class UploadManager extends EventEmitter {
	// eslint-disable-next-line space-before-function-paren
	constructor() {
		super()
		this.runningJobs = 0
		this.queue = []
	}
	addJob (job) {
		if (this.runningJobs < MAX_CONCURRENT_UPLOADS) {
			this.runningJobs++
			this.runJob(job)
		} else {
			this.queue.push(job)
		}
	}
	onJobFinished () {
		this.runningJobs--

		if (this.queue.length > 0) {
			const jobToRun = this.queue[0]
			this.queue.splice(0, 1)
			this.runningJobs++
			this.runJob(jobToRun)
		} else {
			AppEventBus.emit(AppEvents.UPLOAD_ENDED)
		}
	}
	runJob (job) {
		return job
			.run()
			.then(res => {
				this.emit(`${job.uploadId}_UPLOADED`, res)
			})
			.finally(() => {
				this.onJobFinished()
			})
	}
	upload (url, file, params = {}) {
		const uploadId = uuid()
		const uploadObject = {
			id: uploadId,
			name: file.name,
			size: file.size,
			progression: 0
		}
		AppEventBus.emit(AppEvents.ADD_UPLOAD_PROGRESS, uploadObject)
		const job = new Job(url, file, uploadObject, params)
		this.addJob(job)
		return new Promise(resolve => {
			this.once(`${uploadId}_UPLOADED`, data => resolve(data))
		})
	}
}

const UploadManagerSingleton = new UploadManager()

export default UploadManagerSingleton
