export default {
	namespaced: true,
	state: {
		hasSideBar: false,
		minimized: false,
		permanent: false,
		show: false,
	},
	mutations: {
		setHasSideBar: function (state, hasSideBar) {
			state.hasSideBar = hasSideBar;
		},
		setMinimized: function (state, minimized) {
			state.minimized = minimized;
		},
		setPermanent: function (state, permanent) {
			state.permanent = permanent;
		},
		setShow: function (state, show) {
			state.show = show;
		},
	},
	actions: {
		reset: function ({ commit }) {
			commit('setHasSideBar', false);
			commit('setMinimized', false);
			commit('setPermanent', false);
			commit('setShow', false);
		},
	}
}
