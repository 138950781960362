// ============================================================================
// OauthProviderService
// -----------------------
// OauthProvider module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/OauthProviderApi'
import { store } from '@/store'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        oauthProvider: {
            create: NotImplementedYet,
            read: NotImplementedYet,
            update: NotImplementedYet,
            delete: NotImplementedYet
        },
        oauthProviders: {
            create: NotImplementedYet,
            read: function (params = null) {
                return API.oauthProviders.read(params)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    service: {
        oauthProvider: {
            create: NotImplementedYet,
            read: NotImplementedYet,
            update: NotImplementedYet,
            delete: NotImplementedYet
        },
        oauthProviders: {
            create: NotImplementedYet,
            read: function (params = null) {
                return Private.requests.oauthProviders.read(params).then(oauthProviders => {
                    return Private.store.oauthProviders.set(oauthProviders).then(() => {
                        return Private.store.oauthProviders.get()
                    })
                })
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    store: {
        oauthProviders: {
            set: function (oauthProviders) {
                RequiredArguments({ oauthProviders: oauthProviders })
                return store.dispatch('oauthProviders/set', oauthProviders)
            },
            get: function () {
                return store.getters['oauthProviders/get']
            },
        } 
      
    }
}
// -------
// Exports
// -------
export default {
    createOauthProvider: Private.service.oauthProvider.create,
    getOauthProvider: Private.service.oauthProvider.read,
    updateOauthProvider: Private.service.oauthProvider.update,
    deleteOauthProvider: Private.service.oauthProvider.delete,

    createOauthProviders: Private.service.oauthProviders.create,
    getOauthProviders: Private.service.oauthProviders.read,
    updateOauthProviders: Private.service.oauthProviders.update,
    deleteOauthProviders: Private.service.oauthProviders.delete


}