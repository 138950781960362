export default {
	namespaced: true,
	state: {
		data: null,
		fullname: null,
		name: null,
		abbreviation: null,
		initials: null,
		title: null,
		id: null,
		firstname: null,
		lastname: null,
		email: null,
		darkMode: null
	},
	getters: {
		getDarkModeState: state => {
			return state.darkMode
		}
	},
	mutations: {
		set (state, user) {
			state.data = user
			if (user.firstname && user.lastname) {
				state.fullname = (user.title ? user.title + ' ' : '') + user.firstname + ' ' + user.lastname
				state.name = user.firstname + ' ' + user.lastname
			}
			if (user.firstname && user.firstname.trim().length > 0) {
				state.firstname = user.firstname
				state.abbreviation = user.firstname[0] + '. ' + user.lastname
			} else {
				state.firstname = ''
			}
			if (user.lastname && user.lastname.trim().length > 0) {
				state.lastname = user.lastname
				if (user.firstname && user.firstname.trim().length > 0) {
					state.initials = user.firstname[0] + user.lastname[0]
				}
			} else {
				state.lastname = ''
			}
			state.title = user.title
			state.id = user.id
			state.email = user.email
		},
		setDark (state, darkMode) {
			state.darkMode = darkMode
		},
		reset: function (state) {
			state.data = null
			state.fullname = null
			state.name = null
			state.abbreviation = null
			state.initials = null
			state.title = null
			state.id = null
			state.firstname = null
			state.lastname = null
			state.email = null
		}
	},
	actions: {
		setUser: function ({ commit }, user) {
			return commit('set', user)
		},
		setDarkMode: function ({ commit }, darkMode) {
			commit('setDark', darkMode)
		},
		reset: function ({ commit }) {
			commit('reset')
		}
	}
}
