// ============================================================================
// OauthProviderApi
// -------------------
// OauthProvider api
// ============================================================================
// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	oauthProvider: {
        create: NotImplementedYet,
        read: NotImplementedYet,
        update: NotImplementedYet,
        delete: NotImplementedYet
    },
    oauthProviders: {
        create: NotImplementedYet,
        read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            return Backend.fetchApi.GET(
                URLS.api.oauth.providers.uri,
                [],
                params,
                doCancelPreviousRequest,
                doCancelAllOtherRequests,
                Backend.fetchApi.priority.low
            )},
        update: NotImplementedYet,
        delete: NotImplementedYet
    }
}