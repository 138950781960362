import { router as ROUTER } from '@/router'

export default function (to, from, lastFullPath) {
	let result = true

	const fromMeta = ROUTER.getMeta(from)
	if (fromMeta.historize === false && to.fullPath != lastFullPath) {
		result = { ...to }
		result.replace = true
	}

	return result
}
