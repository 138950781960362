const EurexQuickfolders = () => import('@/modules/EurexQuickfolders')
const EverialSSO = () => import('@/modules/EverialSSO')

export default [
	{
		component: EverialSSO,
		meta: {
			historize: false
		},
		name: 'everial-ecm',
		path: 'everial/:context?'
	},
	{
		component: EurexQuickfolders,
		name: 'eurex-quickfolders',
		path: 'everial-quickfolders/:context?'
	}
]
