import { router as ROUTER } from '@/router'
import AuthService from '@/services/Auth/AuthService'

export default function (to, from) {
	let result = true

	if (
		to.matched.some(record => {
			const meta = ROUTER.getMeta(record)
			return meta.anonymity_required
		}) &&
		AuthService.getIsAuthenticated()
	) {
		result = {
			name: 'home'
		}
		if (from?.query?.redirect) {
			if (from.query.redirect.includes('/')) {
				result = {
					fullPath: from.query.redirect
				}
			} else {
				result = {
					name: from.query.redirect
				}
			}
		}
	}
	return result
}
