import Exception from '@/exceptions/Exception'
import { i18n } from '@/plugins/i18n'

export default class GlobalException extends Exception {
	constructor (message) {
		if (!message) {
			message = i18n.t('errors.global_error')
		}
		super(message, 'GlobalException')
	}
}
