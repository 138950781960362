// Container
const CustomerDetails = () => import('@/components/CustomersManager/CustomerDetails')

// Pages
const CustomerDetailsAddresses = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsAddresses')
const CustomerDetailsAdministration = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsAdministration')
const CustomerDetailsAutomation = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsAutomation')
const CustomerDetailsBank = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsBank')
const CustomerDetailsFiscal = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsFiscal')
const CustomerDetailsGeneral = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsGeneral')
const CustomerDetailsNotes = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsNotes')
const CustomerDetailsOffers = () => import('@/components/Offers/CustomersManager/ProjectsContainer')
const CustomerDetailsPeriods = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsPeriods')
const CustomerDetailsThemes = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsThemes')
const CustomerDetailsUsers = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsUsers')
const CustomerDetailsWorkflow = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsWorkflow')

const ProjectDrawer = () => import('@/components/Offers/CustomersManager/ProjectDrawer')
const ProjectWizard = () => import('@/components/Offers/CustomersManager/Forms/ProjectWizard')

const WorkflowDrawer = () => import('@/components/CustomersManager/CustomerDetails/Workflows/WorkflowDrawer')

export default [
	{
		component: CustomerDetails,
		meta: {
			historize: false,
			client: false
		},
		name: 'customer',
		path: 'customer/:context?',
		redirect: { name: 'customer-general' },

		children: [
			{
				component: CustomerDetailsAddresses,
				name: 'customer-addresses',
				path: 'addresses'
			},
			{
				component: CustomerDetailsAdministration,
				name: 'customer-administration',
				path: 'administration'
			},
			{
				component: CustomerDetailsAutomation,
				name: 'customer-automation',
				path: 'automation'
			},
			{
				component: CustomerDetailsBank,
				name: 'customer-banks',
				path: 'banks'
			},
			{
				component: CustomerDetailsFiscal,
				name: 'customer-fiscal',
				path: 'fiscal'
			},
			{
				component: CustomerDetailsGeneral,
				name: 'customer-general',
				path: 'general'
			},
			{
				component: CustomerDetailsNotes,
				name: 'customer-notes',
				path: 'notes'
			},
			{
				component: CustomerDetailsOffers,
				name: 'customer-services',
				path: 'services',
				props: route => {
					let projectId, serviceId
					if (route.params.projectId) {
						projectId = parseInt(route.params.projectId, 16)
					}
					if (route.params.serviceId) {
						serviceId = parseInt(route.params.serviceId, 16)
					}
					return {
						projectId,
						serviceId
					}
				},

				children: [
					{
						component: ProjectWizard,
						meta: {
							historize: false
						},
						name: 'customer-services-wizard',
						path: 'create/:projectId?',
						props: route => {
							let projectId

							if (route.params.projectId) {
								projectId = parseInt(route.params.projectId, 16)
							}

							return { projectId }
						}
					},
					{
						component: ProjectDrawer,
						meta: {
							historize: false
						},
						name: 'customer-services-project',
						path: ':projectId?/:serviceId?',
						props: route => {
							let projectId
							if (route.params.projectId) {
								projectId = parseInt(route.params.projectId, 16)
							}
							let serviceId
							if (route.params.serviceId) {
								serviceId = parseInt(route.params.serviceId, 16)
							}
							return {
								projectId,
								serviceId
							}
						}
					}
				]
			},
			{
				component: CustomerDetailsPeriods,
				name: 'customer-periods',
				path: 'financial-years'
			},
			{
				component: CustomerDetailsThemes,
				name: 'customer-themes',
				path: 'themes'
			},
			{
				component: CustomerDetailsUsers,
				name: 'customer-users',
				path: 'users'
			},
			{
				component: CustomerDetailsWorkflow,
				name: 'customer-workflows',
				path: 'workflows',

				children: [
					{
						component: WorkflowDrawer,
						meta: {
							historize: false
						},
						name: 'customer-workflows-drawer',
						path: ':workflowId',
						props: route => {
							const workflowId = route.params.workflowId !== 'create'
								? Number.parseInt(route.params.workflowId, 16)
								: null;

							return { value: workflowId, backLink: route.query?.backLink };
						}
					}
				]
			}
		]
	}
]
