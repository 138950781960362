const SuperAdmin = () => import('@/modules/SuperAdmin')

import home from '@/router/routes/auth/superAdmin/home'
import accountingFirms from '@/router/routes/auth/superAdmin/accountingFirms'
import applications from '@/router/routes/auth/superAdmin/applications'
import customers from '@/router/routes/auth/superAdmin/customers'
import flags from '@/router/routes/auth/superAdmin/flags'
import mobileApps from '@/router/routes/auth/superAdmin/mobileApps'
import modules from '@/router/routes/auth/superAdmin/modules'
import npsSurveys from '@/router/routes/auth/superAdmin/npsSurveys'
import support from '@/router/routes/auth/superAdmin/support'
import themes from '@/router/routes/auth/superAdmin/themes'
import treeStructures from '@/router/routes/auth/superAdmin/treeStructures'
import products from '@/router/routes/auth/superAdmin/products'

export default [
	{
		component: SuperAdmin,
		meta: {
			super_admin: true
		},
		name: 'sa',
		path: 'sa',
		redirect: 'sa-home',
		children: [...home, ...accountingFirms, ...applications, ...customers, ...flags, ...mobileApps, ...modules, ...npsSurveys, ...support, ...themes, ...treeStructures, ...products]
	}
]
