const ProductManager = () => import('@/modules/ProductManager')

export default [
	{
		component: ProductManager,
		name: 'sa-products',
		path: 'products',
		props: false,
	}
]
