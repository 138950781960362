import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const autoLogin = (config = {}) => {
	return axios.get('autologin', config)
}

const retrieveLogin = (config = {}) => {
	return axios.get('retrievelogin', config)
}

const login = data => {
	return axios.post('login', data)
}

const logout = () => {
	return axios.get('logout')
}

export default {
	autoLogin,
	retrieveLogin,
	login,
	logout
}
