import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const hasAccessToGest4u = (accountingFirmId, vendorId) => {
	const url = `third-party/gest4u/${accountingFirmId}/${vendorId}/access`
	return axios.get(url)
}

const getGest4uCredentials = (accountingFirmId, vendorId) => {
	const url = `third-party/gest4u/${accountingFirmId}/${vendorId}/credentials`
	return axios.get(url)
}

export default {
	hasAccessToGest4u: hasAccessToGest4u,
	getGest4uCredentials: getGest4uCredentials
}
