// Container

// Pages
const AutoLoginCallback = () => import('@/views/AutoLoginCallBack')

export default [
	{
		component: AutoLoginCallback,
		meta: {
			auto_login: false,
			historize: false
		},
		name: 'autologin',
		path: 'autologin',
		props: route => {
			let redirect
			let mobileApp = false
			if (route.query.redirect) {
				redirect = decodeURIComponent(route.query.redirect)
				mobileApp = redirect.includes('embedded=genericmobileapp') || redirect.includes('embedded=mobileapp')
			}
			return {
				redirect,
				refreshToken: route.query.refresh_token !== false && route.query.refresh_token !== 'false',
				token: route.query.token,
				isMobileApp: mobileApp
			}
		}
	}
]
