export class DocumentVersion {
	_signatureStatus = null

	constructor (rawVersion) {
		Object.assign(this, rawVersion)
	}

	get signatures () {
		return this._signatureStatus
	}
	set signatures (signatures) {
		this._signatureStatus = signatures
	}
	update (updatedVersion) {
		Object.assign(this, updatedVersion)
	}
}
