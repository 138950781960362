import projects from '@/store/modules/offers/projects'

export default {
	namespaced: true,
	modules: {
		projects
	},
	state: {},
	mutations: {
		reset: function () {}
	},
	actions: {
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
