// ============================================================================
// VueX - Notifications
// ----------------
// Company assignment VueX store module
// ============================================================================

export default {
	namespaced: true,
	state: {
		cancelTokens: {
			listCompanies: null
		},
		filters: {
			search: null,
			users: []
		},
		users: []
	},
	mutations: {
		setCancelToken: function (state, cancelTokenValue) {
			if (state.cancelTokens.hasOwnProperty(cancelTokenValue.item)) {
				state.cancelTokens[cancelTokenValue.item] = cancelTokenValue.cancelToken
			}
		},
		setFilters: function (state, filters) {
			state.filters = filters
		},
		clearUsers: function (state) {
			state.users.clear()
		},
		setUsers: function (state, users) {
			state.users = users
		},
		reset: function () {}
	},
	actions: {
		setCancelToken: function ({ commit }, cancelTokenValue) {
			return commit('setCancelToken', cancelTokenValue)
		},
		setFilters: function ({ commit }, filters) {
			return commit('setFilters', filters)
		},
		clearUsers: function ({ commit }) {
			return commit('clearUsers')
		},
		setUsers: function ({ commit }, users) {
			return commit('setUsers', users)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
