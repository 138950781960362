import Exception from '@/exceptions/Exception'
import { i18n } from '@/plugins/i18n'

export default class UnprocessableEntityException extends Exception {
	constructor (message, errors = []) {
		if (!message || message.trim().length === 0) {
			message = i18n.t('errors.unprocessable_entity')
		}
		super(message, 'UnprocessableEntityException')
		this.errors = errors
	}
}
