import frFR from './ressources/fr-FR__numberFormats.json'
import enUS from './ressources/en-US__numberFormats.json'
import ptPT from './ressources/pt-PT__numberFormats.json'

export default {
	fr: frFR,
	'fr-FR': frFR,
	'en-US': enUS,
	'pt-PT': ptPT
}
