export default {
	namespaced: true,
	state: {
		home: [],
		list: [],
		navbar: [],
		settings: [],
		holding: []
	},
	mutations: {
		setHome (state, modules) {
			state.home.clear()
			modules.forEach(item => {
				state.home.push(item)
			})
		},
		setList (state, modules) {
			state.list.clear()
			modules.forEach(item => {
				state.list.push(item)
			})
		},
		setNavbar (state, modules) {
			state.navbar.clear()
			modules.forEach(item => {
				state.navbar.push(item)
			})
			modules.sort((a, b) => a.priority - b.priority)
		},
		setSettings (state, modules) {
			state.settings.clear()
			modules.forEach(item => {
				state.settings.push(item)
			})
		},
		setHolding (state, modules) {
			state.holding.clear()
			modules.forEach(item => {
				state.holding.push(item)
			})
		},
		reset (state) {
			state.home.clear()
			state.list.clear()
			state.navbar.clear()
			state.settings.clear()
		}
	},
	actions: {
		setHome: function ({ commit }, data) {
			return commit('setHome', data)
		},
		setList: function ({ commit }, data) {
			return commit('setList', data)
		},
		setNavbar: function ({ commit }, data) {
			return commit('setNavbar', data)
		},
		setSettings: function ({ commit }, data) {
			return commit('setSettings', data)
		},
		setHolding: function ({commit}, data) {
			return  commit('setHolding', data)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	},
	getters: {
		getHoldingModules: function (state) {
			return state.holding
		},
		activeModules: function (state) {
			return new Set(
				state.list
					.filter(module => module.is_active)
					.map(module => module.name)
			);
		},
	}
}
