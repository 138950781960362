// ============================================================================
// VueX - Signature
// ----------------
// Signature VueX store module
// ============================================================================
export default {
	namespaced: true,
	state: {
		oauthProviders: []
	},
	mutations: {
        setOauthProviders: function (state, data) {
			state.oauthProviders = data
		},
	},
	actions: {
		set: function ({ commit }, oauthProviders) {
			return commit('setOauthProviders', oauthProviders)
		},
	},
	getters: {
		get: function (state) {
			return state.oauthProviders
		},
	}
}
