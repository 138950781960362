export default {
	namespaced: true,
	state: {
		list: [],
		paginationOffset: 15,
		selected: null,
		verticalDisplayMode: true,
		themes: [],
		members: [],
		companies: [],
		statuses: [],
		types: [],
		filters: {
			adminMode: false,
			dueDateInterval: {
				afterDate: null,
				beforeDate: null
			},
			search: '',
			themes: [],
			internalTasks: true,
			members: [],
			statuses: [],
			companies: []
		},
		sorting: {
			byCompany: '',
			byDueDate: '',
			byName: '',
			byStatus: ''
		},
		cancelTokens: {
			companies: null,
			taskslist: null,
			counters: null
		}
	},
	mutations: {
		setList: function (state, taskList) {
			state.list.clear()
			if (taskList) {
				state.list = [...taskList]
			}
		},
		addToList: function (state, task) {
			if (task) {
				state.list.unshift(task)
			}
		},
		setPaginationOffset (state, paginationOffset) {
			state.paginationOffset = paginationOffset
		},
		setSelected: function (state, task) {
			state.selected = task
		},
		setVerticalDisplayMode: function (state, value) {
			state.verticalDisplayMode = value
		},
		setStatuses: function (state, statuses) {
			state.statuses.clear()
			state.statuses = [...statuses]
		},
		setTypes: function (state, types) {
			state.types.clear()
			state.types = [...types]
		},
		setThemes: function (state, themes) {
			state.themes.clear()
			state.themes = [...themes]
		},
		setCompanies: function (state, companies = []) {
			state.companies.clear()
			state.companies = [...companies]
		},
		setMembers: function (state, members) {
			state.members.clear()
			state.members = [...members]
		},
		reset: function (state) {
			state.list = []
			state.paginationOffset = 15
			state.selected = null
			state.verticalDisplayMode = true
			state.themes = []
			state.members = []
			state.companies = []
			state.statuses = []
			state.types = []
			state.filters = {
				adminMode: false,
				dueDateInterval: {
					afterDate: null,
					beforeDate: null
				},
				search: '',
				themes: [],
				internalTasks: true,
				members: [],
				statuses: [],
				companies: []
			}
			state.sorting = {
				byCompany: '',
				byDueDate: '',
				byName: '',
				byStatus: ''
			}
			state.cancelTokens = {
				companies: null,
				taskslist: null,
				counters: null
			}
		},
		setCancelToken: function (state, cancelTokenValue) {
			if (state.cancelTokens.hasOwnProperty(cancelTokenValue.item)) {
				state.cancelTokens[cancelTokenValue.item] = cancelTokenValue.cancelToken
			}
		},
		unsetCancelToken: function (state, cancelTokenValue) {
			if (state.cancelTokens.hasOwnProperty(cancelTokenValue.item) && state.cancelTokens[cancelTokenValue.item] === cancelTokenValue.cancelToken) {
				state.cancelTokens[cancelTokenValue.item] = null
			}
		}
	},
	actions: {
		setList ({ commit }, value) {
			return Promise.resolve(commit('setList', value))
		},
		addToList ({ commit }, value) {
			return Promise.resolve(commit('addToList', value))
		},
		setPaginationOffset ({ commit }, value) {
			return Promise.resolve(commit('setPaginationOffset', value))
		},
		setSelected ({ commit }, value) {
			return Promise.resolve(commit('setSelected', value))
		},
		setVerticalDisplayMode ({ commit }, value) {
			return Promise.resolve(commit('setVerticalDisplayMode', value))
		},
		setStatuses: function ({ commit }, statuses) {
			return Promise.resolve(commit('setStatuses', statuses))
		},
		setTypes: function ({ commit }, types) {
			return Promise.resolve(commit('setTypes', types))
		},
		setThemes: function ({ commit }, themes) {
			return Promise.resolve(commit('setThemes', themes))
		},
		setCompanies: function ({ commit }, companies) {
			return Promise.resolve(commit('setCompanies', companies))
		},
		setMembers: function ({ commit }, members) {
			return Promise.resolve(commit('setMembers', members))
		},
		setCancelToken: function ({ commit }, cancelTokenValue) {
			return Promise.resolve(commit('setCancelToken', cancelTokenValue))
		},
		unsetCancelToken: function ({ commit }, cancelTokenValue) {
			return Promise.resolve(commit('unsetCancelToken', cancelTokenValue))
		},
		reset: function ({ commit }) {
			return Promise.resolve(commit('reset'))
		}
	}
}
