import Exception from '@/exceptions/Exception'
import { i18n } from '@/plugins/i18n'

export default class RequestTimeoutException extends Exception {
	constructor (message = null) {
		if (message === null || message === '') {
			message = i18n.t('errors.request_timeout')
		} else if (message === 'checksum-failed') {
			message = i18n.t('errors.checksum_failed')
		}
		super(message, 'RequestTimeoutException')
	}
}
