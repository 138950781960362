// ============================================================================
// VueX - Notifications
// ----------------
// Notifications VueX store module
// ============================================================================

export default {
	namespaced: true,
	state: {
		cancelTokens: {
			listCompanies: null
		},
		families: [],
		frequencies: [],
		sources: []
	},
	mutations: {
		setCancelToken: function (state, cancelTokenValue) {
			if (state.cancelTokens.hasOwnProperty(cancelTokenValue.item)) {
				state.cancelTokens[cancelTokenValue.item] = cancelTokenValue.cancelToken
			}
		},
		setFamilies: function (state, data) {
			state.families = data
		},
		setFrequencies: function (state, data) {
			state.frequencies = data
		},
		setSources: function (state, data) {
			state.sources = data
		},
		updateFamily: function (state, data) {
			const index = state.families.findIndex(f => f.id === data.id)
			if (index !== -1) {
				Object.assign(state.families[index], data)
			}
		},
		reset: function (state) {
			state.cancelTokens.listCompanies = null
			state.families = []
			state.frequencies = []
		}
	},
	actions: {
		setCancelToken: function ({ commit }, cancelTokenValue) {
			return commit('setCancelToken', cancelTokenValue)
		},
		setFamilies: function ({ commit }, items) {
			return commit('setFamilies', items)
		},
		setFrequencies: function ({ commit }, item) {
			return commit('setFrequencies', item)
		},
		setSources: function ({ commit }, sources) {
			return commit('setSources', sources)
		},
		updateFamily: function ({ commit }, item) {
			return commit('updateFamily', item)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
