import Vue from 'vue'

export class EventBus {
	constructor () {
		this.registeredComponents = []
		this.listeners = []
		this.bus = new Vue()
		if (!this.bus.$on) {
			throw new Error('Created bus instance not providing "$on" method')
		}
		if (!this.bus.$off) {
			throw new Error('Created bus instance not providing "$off" method')
		}
		if (!this.bus.$emit) {
			throw new Error('Created bus instance not providing "$emit" method')
		}
	}
	___getComponentName (component = null) {
		let result = null
		if (component) {
			result = component._uid
		}
		return result
	}
	initializeListeners (eventActionMapping = null, component = null) {
		if (!eventActionMapping) {
			throw new Error('parameter "eventActionMapping" is null or missing')
		} else if (!(eventActionMapping instanceof Array)) {
			throw new Error('parameter "eventActionMapping" is not an Array')
		} else if (!component) {
			throw new Error('parameter "component" is null or missing')
		} else {
			let componentName = this.___getComponentName(component)
			if (!this.registeredComponents[component.$options._componentTag]) {
				this.registeredComponents[component.$options._componentTag] = []
			}
			this.registeredComponents[component.$options._componentTag].push(componentName)
			if (!this.listeners[componentName]) {
				this.listeners[componentName] = []
			}
			eventActionMapping.map(mappingEntry => {
				if (!mappingEntry.event) {
					throw new Error('eventActionMapping entry has no event defined')
				} else if (!mappingEntry.action) {
					throw new Error('eventActionMapping entry for event "' + mappingEntry.event.description + '" has no action defined')
				} else {
					this.listeners[componentName].push({ event: mappingEntry.event, action: mappingEntry.action })
					this.bus.$on(mappingEntry.event, mappingEntry.action)
				}
			})
		}
	}
	releaseListeners (component = null) {
		if (!component) {
			throw new Error('parameter "component" is null or missing')
		} else {
			const componentName = this.___getComponentName(component)
			if (this.listeners[componentName]) {
				this.listeners[componentName].map(eventAction => {
					this.bus.$off(eventAction.event, eventAction.action)
				})
				this.listeners[componentName] = null
				const registeredComponentIndex = this.registeredComponents.findIndex(registeredComponent => registeredComponent.includes(componentName))
				if (registeredComponentIndex != -1) {
					const registeredComponent = this.registeredComponents[registeredComponentIndex]
					const componentNameIndex = registeredComponent.findIndex(registeredComponentUniqueName => registeredComponentUniqueName == componentName)
					registeredComponent.splice(componentNameIndex, 1)
					if (registeredComponent.length == 0) {
						this.registeredComponents.splice(registeredComponentIndex, 1)
					}
				}
			}
		}
	}
	emit (event = null, arg = null) {
		if (!event) {
			throw new Error('parameter "event" is null or missing')
		}
		return this.bus.$emit(event, arg)
	}
}
