export default {
	namespaced: true,
	state: {
		preferences: {}
	},
	mutations: {
		setPreferences: function (state, preferences) {
			Object.assign(state.preferences, preferences)
		},
		reset: function () {}
	},
	actions: {
		setPreferences: function ({ commit }, preferences) {
			return commit('setPreferences', preferences)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
