export default {
	namespaced: true,
	state: {
		isAuthenticated: false,
		token: localStorage.getItem('token'),
		isSuperAdmin: false,
		isAccountant: false
	},
	mutations: {
		setIsAuthenticated (state, isAuthenticated) {
			state.isAuthenticated = isAuthenticated
		},
		setToken (state, token) {
			state.token = token
			if (token) {
				localStorage.setItem('token', token)
			} else {
				localStorage.removeItem('token')
			}
		},
		setIsSuperAdmin (state, isSuperAdmin) {
			state.isSuperAdmin = isSuperAdmin
		},
		setIsAccountant (state, isAccountant) {
			state.isAccountant = isAccountant
		},
		reset: function (state) {
			localStorage.removeItem('token')
			state.isAuthenticated = false
			state.token = null
			state.isSuperAdmin = false
			state.isAccountant = false
		}
	},
	actions: {
		setIsAuthenticated: function ({ commit }, isAuthenticated) {
			commit('setIsAuthenticated', isAuthenticated)
		},
		setToken: function ({ commit }, token) {
			commit('setToken', token)
		},
		setIsSuperAdmin: function ({ commit }, isSuperAdmin) {
			commit('setIsSuperAdmin', isSuperAdmin)
		},
		setIsAccountant: function ({ commit }, isAccountant) {
			commit('setIsAccountant', isAccountant)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
