// ============================================================================
// VueX - Notifications
// ----------------
// Team Manager VueX store module
// ============================================================================

import companyAssignment from '@/store/modules/teamManager/companyAssignment'

export default {
	namespaced: true,
	modules: {
		companyAssignment
	},
	state: {},
	mutations: {
		reset: function () {}
	},
	actions: {
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
