export default {
	namespaced: true,
	state: {
		teamManager: {
			cancelTokens: {
				getUsers: null
			},
			filters: {
				search: null,
				themes: []
			},
			themes: []
		}
	},
	mutations: {
		setTeamManagerCancelToken: function (state, cancelTokenValue) {
			if (state.teamManager.cancelTokens.hasOwnProperty(cancelTokenValue.item)) {
				state.teamManager.cancelTokens[cancelTokenValue.item] = cancelTokenValue.cancelToken
			}
		},
		setTeamManagerFilters: function (state, filters) {
			state.teamManager.filters = filters
		},
		setTeamManagerThemes: function (state, themes) {
			state.teamManager.themes = themes
		},
		reset: function (state) {
			state.teamManager = {
				cancelTokens: {
					getUsers: null
				},
				filters: {
					search: null,
					themes: []
				},
				themes: []
			}
		}
	},
	actions: {
		setTeamManagerCancelToken: function ({ commit }, cancelTokenValue) {
			return commit('setTeamManagerCancelToken', cancelTokenValue)
		},
		setTeamManagerFilters: function ({ commit }, filters) {
			return commit('setTeamManagerFilters', filters)
		},
		setTeamManagerThemes: function ({ commit }, themes) {
			return commit('setTeamManagerThemes', themes)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
