export class Node extends Object {
	fetched = false
	_counter = null
	_is_surveyed = null
	_is_root = null
	themes = []
	_folders = []
	_documents = []
	_versions = []
	structure = null

	static isFolderNode (node) {
		return node && node.hasOwnProperty('type') && node.type == 'folder'
	}
	static isDocumentNode (node) {
		return node && node.hasOwnProperty('type') && node.type != 'folder'
	}
	static isRootNode (node) {
		return node && node.hasOwnProperty('folder_id') && node.folder_id == null
	}

	constructor (rawNode) {
		super()
		this.structure = rawNode?.structure
		Object.assign(this, rawNode)
		if (!this.alias) {
			this.alias = this.hash
		}
		if (rawNode) {
			this.fetched = true
		}
	}
	get is_deleted () {
		return this.hasOwnProperty('deleted_at') && this.deleted_at != null
	}
	get is_folder () {
		return Node.isFolderNode(this)
	}
	get is_document () {
		return Node.isDocumentNode(this)
	}
	get is_root () {
		return this._is_root ? this._is_root : Node.isRootNode(this)
	}
	set is_root (is_root) {
		this._is_root = is_root
	}
	get parent () {
		return this.structure && this.folder_id ? this.structure.findNode(this.folder_id) : null
	}
	get fullpath () {
		let result = ''
		if (this.parent) {
			result += this.parent.fullpath
		}
		result += '/' + this.name
		return result
	}
	get is_surveyed () {
		return this._is_surveyed
	}
	set is_surveyed (isSurveyed) {
		this._is_surveyed = isSurveyed
	}
	get counter () {
		return this._counter
	}
	set counter (value) {
		const updateParent = this._counter != null
		this._counter = value
		if (updateParent) {
			this.parent?.updateCounter()
		}
	}
	resetcounter () {
		if (Node.isFolderNode(this)) {
			this.folders?.forEach(folder => {
				folder.resetcounter()
			})
			this.documents?.forEach(document => {
				document.resetcounter()
			})
		}
		this.counter = 0
	}
	update (rawNode) {
		let result = null
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id)) {
			const isSameNode = this.id == rawNode.id || this.hash == rawNode.hash || this.alias == rawNode.alias
			if (isSameNode && (this.folder_id == rawNode.folder_id || rawNode.folder_id == undefined || this.folder_id == -1)) {
				Object.assign(this, rawNode)
				result = this
			} else if (isSameNode) {
				const that = this
				this.parent?.remove(this)
				const newParent = this.structure?.findNode(rawNode.folder_id)
				Object.assign(that, rawNode)
				const existingNode = newParent?.children.find(child => child.id == that.id)
				if (!existingNode) {
					newParent.add(that)
				} else {
					existingNode.update(that)
				}
				result = that
			}
			this.fetched = true
		}
		return result
	}
}
