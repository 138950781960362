const Gest4U = () => import('@/modules/ThirdParty/Gest4U')

export default [
	{
		component: Gest4U,
		meta: {
			accountant: false
		},
		name: 'gest4u',
		path: 'gest4u/:context?'
	}
]
