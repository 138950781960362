export default {
	namespaced: true,
	state: {
		applications: []
	},
	mutations: {
		setApplications: function (state, applications) {
			state.applications.splice(0, state.applications.length, ...applications)
		},
		clearApplications: function (state) {
			state.applications = []
		},
		reset: function () {}
	},
	actions: {
		setApplications: function ({ commit }, applications) {
			return commit('setApplications', applications)
		},
		clearApplications: function ({ commit }) {
			return commit('clearApplications')
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
