const HoldingTeamManager = () => import('@/modules/HoldingTeamManager')

const HoldingTeamList = () => import('@/components/Holding/HoldingTeamList')
const HoldingUserSettings = () => import('@/components/Holding/HoldingUserSettings')

const HoldingUserGeneralInformations = () => import('@/components/Holding/HoldingUserSettings/HoldingUserGeneralInformations')
const HoldingUserSettingsThemes = () => import('@/components/Holding/HoldingUserSettings/Themes')
const HoldingUserVendors = () => import('@/components/Holding/HoldingUserSettings/HoldingUserVendors')
const HoldingUserAccountingFirmAssignment = () => import('@/components/Holding/HoldingUserSettings/HoldingUserAccountingFirmAssignment')

export default [
	{
        component: HoldingTeamManager,
        name: 'holding-team',
        path: 'team',
        meta: {
            accountant: false
        },
        redirect: { name: 'holding-team-list' },
        children: [
            {
                component: HoldingTeamList,
                name: 'holding-team-list',
                path: ''
            },
            {
                component: HoldingUserSettings,
                name: 'holding-team-user',
                path: 'users/:userId',
                redirect: { name: 'holding-team-user-general' },
                children: [
                    {
                        component: HoldingUserGeneralInformations,
                        name: 'holding-team-user-general',
                        path: 'profile'
                    },
                    {
                        component: HoldingUserVendors,
                        name: 'holding-team-user-vendors',
                        path: 'vendors'
                    },
                    {
                        component: HoldingUserSettingsThemes,
                        name: 'holding-team-user-themes',
                        path: 'themes',
                        props: (route) => {
                            return {
                                value: parseInt(route.params.userId)
                            }
                        }
                    },
                    {
                        component: HoldingUserAccountingFirmAssignment,
                        name: 'holding-team-user-accounting-firm-assignment',
                        path: 'assignment',
                    },
                ]
            }
        ]
    }
]