// Container
const CompanySettings = () => import('@/components/Settings/CompanySettings')

// Pages
const CustomerDetailsAddresses = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsAddresses')
const CustomerDetailsAutomation = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsAutomation')
const CustomerDetailsBank = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsBank')
const CustomerDetailsGeneral = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsGeneral')
const CustomerDetailsPeriods = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsPeriods')
const CustomerDetailsThemes = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsThemes')
const CustomerDetailsUsers = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsUsers')
const CustomerDetailsWorkflow = () => import('@/components/CustomersManager/CustomerDetails/CustomerDetailsWorkflow')

const WorkflowDrawer = () => import('@/components/CustomersManager/CustomerDetails/Workflows/WorkflowDrawer')

export default [
	{
		component: CompanySettings,
		meta: {
			accountant: false,
			historize: false
		},
		name: 'company-settings',
		path: 'settings/:context?',
		redirect: { name: 'company-settings-general' },

		children: [
			{
				component: CustomerDetailsAddresses,
				name: 'company-settings-addresses',
				path: 'addresses'
			},
			{
				component: CustomerDetailsAutomation,
				name: 'company-settings-automation',
				path: 'automation'
			},
			{
				component: CustomerDetailsBank,
				name: 'company-settings-banks',
				path: 'banks'
			},
			{
				component: CustomerDetailsGeneral,
				name: 'company-settings-general',
				path: 'general'
			},
			{
				component: CustomerDetailsPeriods,
				name: 'company-settings-periods',
				path: 'financial-years'
			},
			{
				component: CustomerDetailsThemes,
				name: 'company-settings-themes',
				path: 'themes'
			},
			{
				component: CustomerDetailsUsers,
				name: 'company-settings-users',
				path: 'users'
			},
			{
				component: CustomerDetailsWorkflow,
				name: 'company-settings-workflows',
				path: 'workflows',

				children: [
					{
						component: WorkflowDrawer,
						meta: {
							historize: false
						},
						name: 'company-settings-workflows-drawer',
						path: ':workflowId',
						props: route => {
							const workflowId = route.params.workflowId !== 'create'
								? Number.parseInt(route.params.workflowId, 16)
								: null;

							return { value: workflowId, backLink: route.query?.backLink };
						}
					}
				]
			}
		]
	}
]
