import { router as ROUTER } from '@/router'
import AuthService from '@/services/Auth/AuthService'

const ChatModule = () => import('@/modules/ChatModule')

export default [
	{
		component: ChatModule,
		meta: function () {
			const result = {
				context: {}
			}
			if (AuthService.getIsAccountant()) {
				result.context.vendor_id = 'sometimes'
			}
			return result
		},
		name: 'chat',
		path: 'chat/:context?',
		props: route => {
			const { accounting_firm_id: accountingFirmId, vendor_id: vendorId } = ROUTER.decryptContext(route.params.context)
			return {
				context: { accountingFirmId, vendorId }
			}
		}
	},
	{
		name: 'chat-client',
		redirect: '/chat/:context',
		path: 'chat-client/:context?'
	}
]
