export default {
	namespaced: true,
	state: {
		data: []
	},
	mutations: {
		hide (state, id) {
			state.data = state.data.map(o => {
				if (o.id == id) {
					o.is_visible = 0
				}
				return o
			})
		},
		set (state, val) {
			state.data = val
		},
		show (state, id) {
			state.data = state.data.map(o => {
				if (o.id == id) {
					o.is_visible = 1
				}
				return o
			})
		},
		reset: function (state) {
			state.data = []
		}
	},
	actions: {
		set ({ commit }, data) {
			return commit('set', data)
		},
		reset: function ({ commit }) {
			commit('reset')
		}
	}
}
