import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const getWhiteLabels = data => {
	const url = ''
	return axios.get(url, { params: data })
}

const getWhiteLabelsOfAccountingFirm = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/white-labels`

	return axios.get(url)
}

export default {
	getWhiteLabels,
	getWhiteLabelsOfAccountingFirm
}
