export default {
	namespaced: true,
	state: {
		chatNotifications: 0
	},
	mutations: {
		setChatNotifications (state, val) {
			state.chatNotifications = val
		},
		reset: function (state) {
			state.data = []
			state.linkedToAccountingFirmAndUser = []
		}
	},
	actions: {
		setChatNotifications ({ commit }, data) {
			commit('setChatNotifications', data)
		},
		reset: function ({ commit }) {
			commit('reset')
		}
	}
}
