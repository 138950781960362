import { store } from '@/store'

export default {
	name: 'PageTitleMixin',
	computed: {
		accountingFirm: function () {
			return store?.state?.accountingFirm?.selected
		},
		company: function () {
			return store?.state?.company?.selected
		},
		companyName: function () {
			if (!this.company) {
				return undefined
			}

			if (this.isAccountant && this.company.client_code) {
				return `${this.company.company} (${this.company.client_code})`
			}
			return this.company.company
		},
		pageTitle: function () {
			const route = this.$route
			let translatableString
			for (let i = route.matched.length - 1; i >= 0; i--) {
				const routeName = route.matched[i].name
				if (!this.$te(`router.${routeName}`)) {
					continue
				} else if (this.$te(`router.${routeName}.page_title`)) {
					translatableString = `router.${routeName}.page_title`
					break
				} else if (this.isAccountant && this.$te(`router.${routeName}.accountant_page_title`)) {
					translatableString = `router.${routeName}.accountant_page_title`
					break
				} else if (!this.isAccountant && this.$te(`router.${routeName}.customer_page_title`)) {
					translatableString = `router.${routeName}.customer_page_title`
					break
				}
			}

			if (translatableString) {
				return this.$t(translatableString, {
					accountingFirm: this.accountingFirm?.name,
					company: this.companyName,
					username: this.username,
					editedUserName: this.editedUserName

				})
			}
			return ''
		},
		username: function () {
			return store?.state?.user?.data?.username
		},
		editedUserName: function () {
			return store?.state?.holdingUsers?.editedUser?.username ? ' : ' + store?.state?.holdingUsers?.editedUser?.username : ''
		}
	}
}
