import { getFileIconSettings } from '@/helpers/files'

import { Node } from '@/classes/Documents/Node.class'
import { DocumentVersion } from '@/classes/Documents/DocumentVersion.class'

export class Document extends Node {
	icon = {
		color: 'primary',
		icon: 'fas fa-file-alt'
	}

	constructor (rawNode) {
		super(rawNode)
		if (this.deleted_at != null) {
			this.folder_id = -1
		}
		const foundIconSetting = getFileIconSettings(this.type)
		if (foundIconSetting) {
			Object.assign(this.icon, foundIconSetting)
		}
		delete this._folders
		delete this._documents
		if (!this._versions || this._versions.length == 0) {
			this._versions = null
		}
	}

	get versions () {
		return this._versions
	}
	set versions (versions) {
		if (Array.isArray(versions)) {
			if (!this._versions) {
				this._versions = []
			}
			const newVersions = versions.map(version => (version instanceof DocumentVersion ? version : new DocumentVersion(version)))
			newVersions.forEach(newVersion => {
				const existingVersion = this._versions.find(version => version.id == newVersion.id)
				if (existingVersion) {
					existingVersion.update(newVersion)
				} else {
					this._versions.push(newVersion)
				}
			})
			this._versions.forEach((existingVersion, existingVersionIndex) => {
				const newFolder = newVersions.find(newVersion => newVersion.id == existingVersion.id)
				if (!newFolder) {
					this._versions.splice(existingVersionIndex, 1)
				}
			})
		}
	}

	get firstVersion () {
		let result = null
		if (this.versions) {
			if (this.versions.length == 1) {
				result = this.versions[0]
			} else if (this.versions.length > 1) {
				result = this.nextVersion(null)
			}
		}
		return result
	}

	get latestVersion () {
		let result = null
		if (this.versions) {
			if (this.versions.length == 1) {
				result = this.versions[0]
			} else if (this.versions.length > 1) {
				let currentVersion = this.firstVersion
				while (currentVersion != null) {
					result = currentVersion
					currentVersion = this.nextVersion(currentVersion)
				}
			}
		}
		return result
	}

	get isValidated () {
		let result = false
		if (this.latestVersion) {
			result = this.latestVersion.validated_at != null
		} else if (this.validated_at != null) {
			result = true
		} else {
			result = this.is_validated
		}
		return result
	}

	getVersion (version) {
		let result = null
		if (this.versions) {
			result = this.versions.find(existingVersion => existingVersion.id == version.id)
		}
		return result
	}
	addOrUpdateVersion (version) {
		let newVersion
		if (!this.versions || !this.versions.map(existingVersion => existingVersion.id).includes(version?.id)) {
			newVersion = this.addVersion(version)
		} else {
			newVersion = this.updateVersion(version)
		}
		return newVersion
	}
	addVersion (version) {
		const newVersion = new DocumentVersion(version)
		if (!this.versions || !this.versions.map(existingVersion => existingVersion.id).includes(newVersion?.id)) {
			if (!this.versions) {
				this.versions = []
			}
			this.versions.push(newVersion)
		}
		this.__sortVersions()
		return newVersion
	}
	updateVersion (version) {
		const currentVersion = this.versions ? this.versions.find(existingVersion => existingVersion.id === version.id) : null
		if (currentVersion) {
			currentVersion.update(version)
		}
		this.__sortVersions()
		return currentVersion
	}
	removeVersion (version) {
		const currentVersionIndex = this.versions ? this.versions.findIndex(existingVersion => existingVersion.id === version.id) : -1
		if (currentVersionIndex > -1) {
			this.versions.splice(currentVersionIndex, 1)
		}
		this.__sortVersions()
	}
	__sortVersions () {
		this.versions?.sort((a, b) => a.id < b.id)
	}
	previousVersion (currentVersion) {
		let result = null
		if (currentVersion && currentVersion.ancestor_id && this.versions) {
			result = this.versions.find(version => version.id == currentVersion.ancestor_id)
		}
		return result
	}
	nextVersion (currentVersion) {
		let result = null
		if (!currentVersion) {
			result = this.versions?.find(version => version.ancestor_id == null)
		} else if (currentVersion.id) {
			result = this.versions?.find(version => version.ancestor_id == currentVersion.id)
		}
		return result
	}
	removeCounter () {
		this._counter = 0
	}
	toString (indentation = '') {
		return indentation + this.name
	}
}
