import { Node } from '@/classes/Documents/Node.class'
import { Folder } from '@/classes/Documents/Folder.class'
import { Document } from '@/classes/Documents/Document.class'
export class Structure extends Folder {
	alias = 'root'
	ghostNodes = []

	constructor (rawNode) {
		super(rawNode)
		delete this._documents
		this.structure = this
	}

	get documents () {
		return undefined
	}
	set documents (_documents) {
		// Do nothing
	}

	get opened () {
		return false
	}
	set opened (_opened) {
		// Do nothing
	}

	get openedFolders () {
		let result = []
		this._folders
			?.map(folder => folder.openedFolders)
			.flat()
			.forEach(folder => {
				const folderIndex = result.findIndex(entry => entry.id == folder.id)
				if (folderIndex == -1) {
					result.push(folder)
				}
			})
		return result
	}
	set openedFolders (_value) {
		// Do nothing
	}
	addOrUpdateNode (rawNode) {
		let result = null
		const rawNodeVendorId = rawNode.vendor ? rawNode.vendor.id : rawNode.vendor_id
		if (rawNode && (!this.vendor?.id || rawNodeVendorId == this.vendor.id)) {
			if (rawNode.is_root && rawNode.folder_id == null && !rawNode.alias) {
				rawNode.alias = 'root'
			}
			const parentFolder = rawNode.folder_id ? this.findNode(rawNode.folder_id) : null
			const existingNode = this.findExistingNode(rawNode, parentFolder)
			if (existingNode) {
				result = existingNode.update(rawNode)
			} else if (parentFolder) {
				result = parentFolder.add(rawNode)
			} else {
				result = this.__addGhostNode(rawNode)
			}
		}
		return result
	}
	__addGhostNode (rawNode) {
		if (!this.structure) {
			this.structure = this
		}
		rawNode.structure = this.structure
		let node
		if (Node.isFolderNode(rawNode)) {
			node = new Folder(rawNode)
		} else if (Node.isDocumentNode(rawNode)) {
			node = new Document(rawNode)
		}
		this.structure.ghostNodes.push(node)
		return node
	}
	findExistingNode (rawNode, parentFolder) {
		let existingNode
		if (rawNode.id) {
			existingNode = this.findNode(rawNode.id)
		}
		if (rawNode.hash && !existingNode) {
			existingNode = this.findNode(rawNode.hash)
		}
		if (rawNode.alias && !existingNode) {
			existingNode = this.findNode(rawNode.alias)
		}
		if (!existingNode && parentFolder && parentFolder.is_folder) {
			existingNode = parentFolder.children?.find(child => child.id == rawNode.id)
		}
		return existingNode
	}
	tryToAttachGhostNodes () {
		const ghostNodes = this.structure?.ghostNodes
		if (ghostNodes) {
			for (const ghostNode of ghostNodes) {
				if (this.structure.findNode(ghostNode.folder_id) || this.structure.findParentNode(ghostNode.id)) {
					const nodeIndex = this.structure.ghostNodes.findIndex(node => node.id == ghostNode.id)
					this.structure.ghostNodes.splice(nodeIndex, 1)
					ghostNode.structure = this.structure
					this.structure.addOrUpdateNode(ghostNode)
				}
			}
		}
	}
	remove (rawNode) {
		let existingNode
		if (rawNode.alias || rawNode.hash || rawNode.id) {
			existingNode = this.findNode(rawNode.alias)
			if (!existingNode) {
				existingNode = this.findNode(rawNode.hash)
			}
			if (!existingNode) {
				existingNode = this.findNode(rawNode.id)
			}
		}
		if (existingNode) {
			if (existingNode.parent instanceof Structure) {
				this._folders = this._folders.filter(subfolder => subfolder.id != existingNode.id)
			} else {
				existingNode.parent.remove(rawNode)
			}
		} else {
			const ghostNodeIndex = this.ghostNodes.findIndex(ghostNode => ghostNode.id == rawNode.id)
			if (ghostNodeIndex != -1) {
				this.ghostNodes.splice(ghostNodeIndex, 1)
			}
		}
	}
}
