export const OSes = {
	ANDROID: 'Android',
	IOS: 'iOS',
	LINUX: 'Linux',
	MACOS: 'MAC OS',
	WINDOWS: 'Windows'
}

export const CurrentOS = (function () {
	const userAgent = window.navigator.userAgent
	const platform = window.navigator.platform
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
	const iosPlatforms = ['iPhone', 'iPad', 'iPod']
	let result = null
	if (macosPlatforms.indexOf(platform) !== -1) {
		result = OSes.MACOS
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		result = OSes.IOS
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		result = OSes.WINDOWS
	} else if (/Android/.test(userAgent)) {
		result = OSes.ANDROID
	} else if (!result && /Linux/.test(platform)) {
		result = OSes.LINUX
	}
	return result
})()

const helpers = {
	isAndroid: (function () {
		return CurrentOS == OSes.ANDROID
	})(),
	isIOS: (function () {
		return CurrentOS == OSes.IOS
	})(),
	isLinux: (function () {
		return CurrentOS == OSes.LINUX
	})(),
	isMacOS: (function () {
		return CurrentOS == OSes.MACOS
	})(),
	isWindows: (function () {
		return CurrentOS == OSes.WINDOWS
	})()
}

export default Object.assign({ current: CurrentOS }, helpers, OSes)
