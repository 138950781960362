const HoldingManager = () => import('@/modules/HoldingManager')

import CustomersRoute from '@/router/routes/auth/holding/customers'
import DashBoardsRoute from '@/router/routes/auth/holding/dashboard'
import WorkspacesRoute from '@/router/routes/auth/holding/workspaces'
import TeamRoute from '@/router/routes/auth/holding/team'

const NoHoldingModuleAvailable = () => import('@/components/Holding/NoHoldingModuleAvailable')

export default [
	{
		component: HoldingManager,
		meta: {
			accountant: false
		},
		name: 'holding-administration',
		path: 'administration/:context?',
		children: [
			...CustomersRoute,
			...DashBoardsRoute,
			...WorkspacesRoute,
			...TeamRoute,
			{
				component: NoHoldingModuleAvailable,
				name: 'no-holding-module',
				path: 'no-holding-module'
			},
		]
	}
]
