export default {
  namespaced: true,
  state: {
    current: {},
  },
  mutations: {
    setCurrent: function (state, document) {
      state.current = document;
    },

    reset: function (state) {
      state.current = {}
    },
  },
  actions: {
    reset: function ({ commit }) {
      return commit('reset')
    }
  },
};
