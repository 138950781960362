export default {
	namespaced: true,
	state: {
		themes: []
	},
	mutations: {
		setThemes: function (state, themes) {
			state.themes.splice(0, state.themes.length, ...themes)
		},
		reset: function () {}
	},
	actions: {
		setThemes: function ({ commit }, themes) {
			return commit('setThemes', themes)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
