const SuperAdminMobileAppManager = () => import('@/modules/SuperAdminMobileAppManager')

export default [
	{
		component: SuperAdminMobileAppManager,
		name: 'sa-mobile-app',
		path: 'mobile-app',
		props: false
	}
]
