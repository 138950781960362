// Container
const OffersModule = () => import('@/modules/OffersModule')

// Pages
const CategoryDetails = () => import('@/components/Offers/CategoryDetails')
const CatalogOfferList = () => import('@/components/Offers/ListOfOffers')

export default [
	{
		component: OffersModule,
		name: 'offers',
		path: 'offers',
		redirect: {
			name: 'offers-category'
		},

		children: [
			{
				component: CatalogOfferList,
				name: 'offers-catalog-offers',
				path: 'catalog-offers'
			},
			{
				component: CategoryDetails,
				name: 'offers-category',
				path: 'categories/:categoryId?',

				props: route => {
					let categoryId = route.params.categoryId

					if (categoryId) {
						categoryId = parseInt(categoryId, 16)
					}

					return {
						categoryId
					}
				}
			},
			{
				component: CategoryDetails,
				name: 'offers-category-catalog-service',
				path: 'categories/:categoryId/catalog-services/:catalogServiceId',

				props: route => {
					const categoryId = parseInt(route.params.categoryId, 16)
					const catalogServiceId = parseInt(route.params.catalogServiceId, 16)
					return {
						catalogServiceId,
						categoryId
					}
				}
			}
		]
	}
]
