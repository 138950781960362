// Container
const ProfileModule = () => import('@/modules/ProfileModule')

// Pages
const ProfileEcmPreferences = () => import('@/views/Profile/ProfileDocumentsView')
const ProfileNotificationsSettings = () => import('@/modules/Profile/NotificationsSettings')
const ProfilePasswordSetting = () => import('@/modules/Profile/PasswordSetting')
const ProfileUserInfo = () => import('@/modules/Profile/UserInfo')
const TermsOfService = () => import('@/views/TermsOfService')

export default [
	{
		component: ProfileModule,
		path: 'profile',
		redirect: {
			name: 'profile-user'
		},

		children: [
			{
				component: ProfileEcmPreferences,
				name: 'profile-ecm-preferences',
				path: 'documents'
			},
			{
				component: ProfileNotificationsSettings,
				name: 'profile-notifications',
				path: 'notifications'
			},
			{
				component: ProfilePasswordSetting,
				name: 'profile-password',
				path: 'password'
			},
			{
				component: TermsOfService,
				name: 'terms-of-service',
				path: 'terms-of-service'
			},
			{
				component: ProfileUserInfo,
				name: 'profile-user',
				path: 'user'
			}
		]
	}
]
