import Vue from 'vue'
import { Config } from '@/services/ConfigService'

import '@/plugins/globalComponents'

import '@/plugins/analytics'
import '@/plugins/config'
import '@/plugins/dragscroll'
import { i18n } from '@/plugins/i18n'
import '@/plugins/helpers'
import '@/plugins/meta'
import '@/plugins/registerServiceWorker'
import '@/plugins/sentry'
import '@/plugins/virtual-scroll'
import '@/plugins/vueDragDrop'
import '@/plugins/vueReactiveProvide'
import '@/plugins/vueObserveVisibility'
import '@/plugins/vuetify'
import '@/plugins/v-viewer'
import '@/plugins/xlsx'

import '@/prototypes/Array'
import '@/prototypes/Promise'

import { store } from '@/store/store'
import { router, sync } from '@/router/router'

sync(store, router)
// Custom styles
import '@/assets/customizeStyle.css'

Vue.config.devtools = !['master', 'staging'].includes(Config.VUE_APP_MODE)
Vue.config.performance = true
Vue.config.silent = ['master'].includes(Config.VUE_APP_MODE)

import App from '@/App'

const start = async function () {
	window.vueInstance = new Vue({
		i18n,
		router: router,
		store: store,
		el: '#main',
		render: h => h(App)
	})
}

start()
