const DashBoardManager = () => import('@/modules/DashBoardManager')

export default [
	{
		component: DashBoardManager,
		meta: {
			accountant: false
		},
		name: 'dashboard',
		path: 'dashboard'
	}
]