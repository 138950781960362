export default {
	namespaced: true,
	state: {
		data: []
	},
	mutations: {
		add (state, val) {
			state.data.push(val)
		},
		remove (state, id) {
			state.data = state.data.filter(o => o.id != id)
		},
		set (state, val) {
			state.data = val
		},
		updateRole (state, data) {
			state.data = state.data.map(o => {
				if (o.id == data.id) {
					o.role_id = data.role_id
				}
				return o
			})
		},
		reset: function (state) {
			state.data = []
		}
	},
	actions: {
		add: ({ commit }, accountant) => {
			return commit('add', accountant)
		},
		delete: ({ commit }, id) => {
			return commit('remove', id)
		},
		set: ({ commit }, accountants) => {
			commit('set', accountants)
		},
		patchRole: ({ commit }, data) => {
			return commit('updateRole', data)
		},
		reset: function ({ commit }) {
			commit('reset')
		}
	}
}
