import AppService from '@/services/AppService'
import { Events, Bus } from '@/events/AppEvents'

import OS from '@/helpers/os'

import EventListenerMixin from '@/mixins/EventListenerMixin'
import KeyboardListenerMixin from '@/mixins/KeyboardListenerMixin'

export function hasModule(ctx) {
	if (!ctx.moduleName) {
		throw new Error("'moduleName' not defined")
	}

	return Array.isArray(ctx.moduleName)
		? ctx.moduleName.some(moduleName => AppService.hasModule(moduleName))
		: AppService.hasModule(ctx.moduleName)
}

const coreMixin= {
	mixins: [EventListenerMixin, KeyboardListenerMixin],
	data: function () {
		return {
			appService: AppService,
			appEventBus: Bus,
			appEvents: Events,
			OS: OS
		}
	},
	computed: {
		hasModule,
	},
	mounted: function () {
		if (!this.hasModule) {
			this.$el.remove()
		}
	}
}

export function AbstractModuleGuardFactory(options = {}) {
	const mixin = {
		...coreMixin
	};

	if (options.injectConditionalDisplay) {
		mixin.inject = ['conditionalDisplay'];
	}

	return mixin;
}

export default {
	...coreMixin,
	inject: ['conditionalDisplay'],
}
