export const KeyboardEventEqual = function (event, keyboardKey) {
	return keyboardKey != null && event != null && (keyboardKey.keys.includes(event.key) || keyboardKey.codes.includes(event.code))
}

export const KeyboardEventHasModifier = function (event, keyboardModifier) {
	return keyboardModifier != null && event != null && event[keyboardModifier]
}

export const KeyboardEvents = {
	keyUp: 'keyup',
	//@deprecated keyPress: 'keypress',
	keyDown: 'keydown'
}

export const KeyboardModifiers = {
	ctrl: 'ctrlKey',
	shift: 'shiftKey',
	alt: 'altKey',
	meta: 'metaKey'
}

export const KeyboardKey = {
	backspace: {
		keys: ['Backspace'],
		codes: ['Backspace']
	},
	tab: {
		keys: ['Tab'],
		codes: ['Tab']
	},
	enter: {
		keys: ['Enter'],
		codes: ['Enter']
	},
	shift: {
		keys: ['Shift'],
		codes: ['ShiftLeft', 'ShiftRight'],
		left: {
			keys: ['Shift'],
			codes: ['ShiftLeft']
		},
		right: {
			keys: ['Shift'],
			codes: ['ShiftRight']
		}
	},
	ctrl: {
		keys: ['Control'],
		codes: ['ControlLeft', 'ControlRight'],
		left: {
			keys: ['Control'],
			codes: ['ControlLeft']
		},
		right: {
			keys: ['Control'],
			codes: ['ControlRight']
		}
	},
	alt: {
		keys: ['Alt'],
		codes: ['AltLeft', 'AltRight'],
		left: {
			keys: ['Alt'],
			codes: ['AltLeft']
		},
		right: {
			keys: ['Alt'],
			codes: ['AltRight']
		}
	},
	meta: {
		keys: ['Meta'],
		codes: ['MetaLeft', 'MetaRight'],
		left: {
			keys: ['Meta'],
			codes: ['MetaLeft']
		},
		right: {
			keys: ['Meta'],
			codes: ['MetaRight']
		}
	},
	contextmenu: {
		keys: ['ContextMenu'],
		codes: ['ContextMenu']
	},
	pause: {
		keys: ['Pause'],
		codes: ['Pause']
	},
	break: {
		keys: ['Pause'],
		codes: ['Pause']
	},
	caps_lock: {
		keys: ['CapsLock'],
		codes: ['CapsLock']
	},
	escape: {
		keys: ['Escape'],
		codes: ['Escape']
	},
	space: {
		keys: [' '],
		codes: ['Space']
	},
	page: {
		keys: ['PageUp', 'PageDown'],
		codes: ['PageUp', 'PageDown'],
		up: {
			keys: ['PageUp'],
			codes: ['PageUp']
		},
		down: {
			keys: ['PageDown'],
			codes: ['PageDown']
		}
	},
	end: {
		keys: ['End'],
		codes: ['End']
	},
	home: {
		keys: ['Home'],
		codes: ['Home']
	},
	arrow: {
		keys: ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp'],
		codes: ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp'],
		down: {
			keys: ['ArrowDown'],
			codes: ['ArrowDown']
		},
		left: {
			keys: ['ArrowLeft'],
			codes: ['ArrowLeft']
		},
		right: {
			keys: ['ArrowRight'],
			codes: ['ArrowRight']
		},
		up: {
			keys: ['ArrowUp'],
			codes: ['ArrowUp']
		}
	},
	print_screen: {
		keys: ['PrintScreen'],
		codes: ['PrintScreen']
	},
	insert: {
		keys: ['Insert'],
		codes: ['Insert']
	},
	delete: {
		keys: ['Delete', 'Del'],
		codes: ['Delete']
	},
	0: {
		keys: ['0'],
		codes: ['Digit0', 'Numpad0']
	},
	1: {
		keys: ['1'],
		codes: ['Digit1', 'Numpad1']
	},
	2: {
		keys: ['2'],
		codes: ['Digit2', 'Numpad2']
	},
	3: {
		keys: ['3'],
		codes: ['Digit3', 'Numpad3']
	},
	4: {
		keys: ['4'],
		codes: ['Digit4', 'Numpad4']
	},
	5: {
		keys: ['5'],
		codes: ['Digit5', 'Numpad5']
	},
	6: {
		keys: ['6'],
		codes: ['Digit6', 'Numpad6']
	},
	7: {
		keys: ['7'],
		codes: ['Digit7', 'Numpad7']
	},
	8: {
		keys: ['8'],
		codes: ['Digit8', 'Numpad8']
	},
	9: {
		keys: ['9'],
		codes: ['Digit9', 'Numpad9']
	},
	digit: {
		0: {
			keys: ['0'],
			codes: ['Digit0']
		},
		1: {
			keys: ['1'],
			codes: ['Digit1']
		},
		2: {
			keys: ['2'],
			codes: ['Digit2']
		},
		3: {
			keys: ['3'],
			codes: ['Digit3']
		},
		4: {
			keys: ['4'],
			codes: ['Digit4']
		},
		5: {
			keys: ['5'],
			codes: ['Digit5']
		},
		6: {
			keys: ['6'],
			codes: ['Digit6']
		},
		7: {
			keys: ['7'],
			codes: ['Digit7']
		},
		8: {
			keys: ['8'],
			codes: ['Digit8']
		},
		9: {
			keys: ['9'],
			codes: ['Digit9']
		}
	},
	a: {
		keys: ['a'],
		codes: ['KeyA']
	},
	b: {
		keys: ['b'],
		codes: ['KeyB']
	},
	c: {
		keys: ['c'],
		codes: ['KeyC']
	},
	d: {
		keys: ['d'],
		codes: ['KeyD']
	},
	e: {
		keys: ['e'],
		codes: ['KeyE']
	},
	f: {
		keys: ['f'],
		codes: ['KeyF']
	},
	g: {
		keys: ['g'],
		codes: ['KeyG']
	},
	h: {
		keys: ['h'],
		codes: ['KeyH']
	},
	i: {
		keys: ['i'],
		codes: ['KeyI']
	},
	j: {
		keys: ['j'],
		codes: ['KeyJ']
	},
	k: {
		keys: ['k'],
		codes: ['KeyK']
	},
	l: {
		keys: ['l'],
		codes: ['KeyL']
	},
	m: {
		keys: ['m'],
		codes: ['KeyM']
	},
	n: {
		keys: ['n'],
		codes: ['KeyN']
	},
	o: {
		keys: ['o'],
		codes: ['KeyO']
	},
	p: {
		keys: ['p'],
		codes: ['KeyP']
	},
	q: {
		keys: ['q'],
		codes: ['KeyQ']
	},
	r: {
		keys: ['r'],
		codes: ['KeyR']
	},
	s: {
		keys: ['s'],
		codes: ['KeyS']
	},
	t: {
		keys: ['t'],
		codes: ['KeyT']
	},
	u: {
		keys: ['u'],
		codes: ['KeyU']
	},
	v: {
		keys: ['v'],
		codes: ['KeyV']
	},
	w: {
		keys: ['w'],
		codes: ['KeyW']
	},
	x: {
		keys: ['x'],
		codes: ['KeyX']
	},
	y: {
		keys: ['y'],
		codes: ['KeyY']
	},
	z: {
		keys: ['z'],
		codes: ['KeyZ']
	},
	A: {
		keys: ['A'],
		codes: ['KeyA']
	},
	B: {
		keys: ['B'],
		codes: ['KeyB']
	},
	C: {
		keys: ['C'],
		codes: ['KeyC']
	},
	D: {
		keys: ['D'],
		codes: ['KeyD']
	},
	E: {
		keys: ['E'],
		codes: ['KeyE']
	},
	F: {
		keys: ['F'],
		codes: ['KeyF']
	},
	G: {
		keys: ['G'],
		codes: ['KeyG']
	},
	H: {
		keys: ['H'],
		codes: ['KeyH']
	},
	I: {
		keys: ['I'],
		codes: ['KeyI']
	},
	J: {
		keys: ['J'],
		codes: ['KeyJ']
	},
	K: {
		keys: ['K'],
		codes: ['KeyK']
	},
	L: {
		keys: ['L'],
		codes: ['KeyL']
	},
	M: {
		keys: ['M'],
		codes: ['KeyM']
	},
	N: {
		keys: ['N'],
		codes: ['KeyN']
	},
	O: {
		keys: ['O'],
		codes: ['KeyO']
	},
	P: {
		keys: ['P'],
		codes: ['KeyP']
	},
	Q: {
		keys: ['Q'],
		codes: ['KeyQ']
	},
	R: {
		keys: ['R'],
		codes: ['KeyR']
	},
	S: {
		keys: ['S'],
		codes: ['KeyS']
	},
	T: {
		keys: ['T'],
		codes: ['KeyT']
	},
	U: {
		keys: ['U'],
		codes: ['KeyU']
	},
	V: {
		keys: ['V'],
		codes: ['KeyV']
	},
	W: {
		keys: ['W'],
		codes: ['KeyW']
	},
	X: {
		keys: ['X'],
		codes: ['KeyX']
	},
	Y: {
		keys: ['Y'],
		codes: ['KeyY']
	},
	Z: {
		keys: ['Z'],
		codes: ['KeyZ']
	},
	numpad: {
		0: {
			keys: ['0'],
			codes: ['Numpad0']
		},
		1: {
			keys: ['0'],
			codes: ['Numpad1']
		},
		2: {
			keys: ['0'],
			codes: ['Numpad2']
		},
		3: {
			keys: ['0'],
			codes: ['Numpad3']
		},
		4: {
			keys: ['0'],
			codes: ['Numpad4']
		},
		5: {
			keys: ['0'],
			codes: ['Numpad5']
		},
		6: {
			keys: ['0'],
			codes: ['Numpad6']
		},
		7: {
			keys: ['0'],
			codes: ['Numpad7']
		},
		8: {
			keys: ['0'],
			codes: ['Numpad8']
		},
		9: {
			keys: ['0'],
			codes: ['Numpad9']
		}
	},
	multiply: {
		keys: ['*'],
		codes: ['NumpadMultiply']
	},
	add: {
		keys: ['+'],
		codes: ['NumpadAdd']
	},
	subtract: {
		keys: ['-'],
		codes: ['NumpadSubtract']
	},
	decimal_point: {
		keys: ['.'],
		codes: ['NumpadDecimal']
	},
	divide: {
		keys: ['/'],
		codes: ['NumpadDivide']
	},
	f1: {
		keys: ['F1'],
		codes: ['F1']
	},
	f2: {
		keys: ['F2'],
		codes: ['F2']
	},
	f3: {
		keys: ['F3'],
		codes: ['F3']
	},
	f4: {
		keys: ['F4'],
		codes: ['F4']
	},
	f5: {
		keys: ['F5'],
		codes: ['F5']
	},
	f6: {
		keys: ['F6'],
		codes: ['F6']
	},
	f7: {
		keys: ['F7'],
		codes: ['F7']
	},
	f8: {
		keys: ['F8'],
		codes: ['F8']
	},
	f9: {
		keys: ['F9'],
		codes: ['F9']
	},
	f10: {
		keys: ['F10'],
		codes: ['F10']
	},
	f11: {
		keys: ['F11'],
		codes: ['F11']
	},
	f12: {
		keys: ['F12'],
		codes: ['F12']
	},
	F1: {
		keys: ['F1'],
		codes: ['F1']
	},
	F2: {
		keys: ['F2'],
		codes: ['F2']
	},
	F3: {
		keys: ['F3'],
		codes: ['F3']
	},
	F4: {
		keys: ['F4'],
		codes: ['F4']
	},
	F5: {
		keys: ['F5'],
		codes: ['F5']
	},
	F6: {
		keys: ['F6'],
		codes: ['F6']
	},
	F7: {
		keys: ['F7'],
		codes: ['F7']
	},
	F8: {
		keys: ['F8'],
		codes: ['F8']
	},
	F9: {
		keys: ['F9'],
		codes: ['F9']
	},
	F10: {
		keys: ['F10'],
		codes: ['F10']
	},
	F11: {
		keys: ['F11'],
		codes: ['F11']
	},
	F12: {
		keys: ['F12'],
		codes: ['F12']
	},
	num_lock: {
		keys: ['NumLock'],
		codes: ['NumLock']
	},
	scroll_lock: {
		keys: ['ScrollLock'],
		codes: ['ScrollLock']
	},
	audio_volume_mute: {
		keys: ['AudioVolumeMute'],
		codes: []
	},
	audio_volume_down: {
		keys: ['AudioVolumeDown'],
		codes: []
	},
	audio_volume_up: {
		keys: ['AudioVolumeUp'],
		codes: []
	},
	media_player: {
		keys: ['LaunchMediaPlayer'],
		codes: []
	},
	launch_application_1: {
		keys: ['LaunchApplication1'],
		codes: []
	},
	launch_application_2: {
		keys: ['LaunchApplication2'],
		codes: []
	},
	semicolon: {
		keys: [';'],
		codes: ['Semicolon']
	},
	equal_sign: {
		keys: ['='],
		codes: ['Equal']
	},
	comma: {
		keys: [','],
		codes: ['Comma']
	},
	dash: {
		keys: ['-'],
		codes: ['Minus']
	},
	period: {
		keys: ['.'],
		codes: ['Period']
	},
	forward_slash: {
		keys: ['/'],
		codes: ['Slash']
	},
	backquote_accent: {
		keys: ['`'],
		codes: ['Backquote']
	},
	open_bracket: {
		keys: ['['],
		codes: ['BracketLeft']
	},
	back_slash: {
		keys: ['\\'],
		codes: ['Backslash']
	},
	close_bracket: {
		keys: [']'],
		codes: ['BracketRight']
	},
	single_quote: {
		keys: ["'"],
		codes: ['Quote']
	},
	quote: {
		keys: ['"'],
		codes: []
	}
}

export default Object.assign(
	{},
	KeyboardKey,
	{
		events: KeyboardEvents
	},
	{
		modifiers: KeyboardModifiers
	},
	{
		helpers: {
			eventEqual: KeyboardEventEqual,
			eventHasModifier: KeyboardEventHasModifier
		}
	}
)
