import AuthService from '@/services/Auth/AuthService'

const TasksManager = () => import('@/modules/TasksManager')

export default [
	{
		component: TasksManager,
		meta: function () {
			const result = { context: {} }
			if (AuthService.getIsAccountant()) {
				result.context.vendor_id = 'never'
			}
			return result
		},
		name: 'tasks',
		path: 'tasks/:context?'
	},
	{
		name: 'information-requests',
		redirect: '/tasks/:context',
		path: 'information-requests/:context?'
	}
]
