const AuthDocumentTemplates = () => import('@/views/AccountingFirmSettings/DocumentTemplates')
const DocumentTemplatesList = () => import('@/components/Documents/Templates/DocumentTemplatesList')
const DocumentTemplatesEditor = () => import('@/components/Documents/Templates/DocumentTemplatesEditor')

export default [
	{
		component: AuthDocumentTemplates,
		path: 'document-templates',
		children: [
			{
				component: DocumentTemplatesList,
				name: 'doc-templates',
				path: 'list'
			},
			{
				component: DocumentTemplatesEditor,
				name: 'doc-templates-editor-new',
				path: 'new'
			},
			{
				component: DocumentTemplatesEditor,
				name: 'doc-templates-editor',
				path: ':documentId',
				props: route => {
					let documentId = []
					if (route.params.documentId) {
						documentId = parseInt(route.params.documentId + ''.trim(), 16) / 1000
					}

					return {
						documentId
					}
				}
			}
		]
	}
]
