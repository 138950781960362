import Vue from 'vue'
import { Config } from '@/services/ConfigService'
import * as SentryVue from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import {
	CaptureConsole as CaptureConsoleIntegration,
	ExtraErrorData as ExtraErrorDataIntegration,
	Debug as DebugIntegration,
	Dedupe as DedupeIntegration,
	Offline as OfflineIntegration,
	ReportingObserver as ReportingObserverIntegration
} from '@sentry/integrations'
import { Replay as ReplayIntegration } from '@sentry/replay'
import { router } from '@/router'

if (Config.VUE_APP_SENTRY_ENABLE) {
	const integrations = []

	if (Config.VUE_APP_SENTRY_DEDUPE_ENABLE) {
		integrations.push(new DedupeIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_EXTRA_ERROR_DATA_ENABLE) {
		integrations.push(new ExtraErrorDataIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_CAPTURE_CONSOLE_ENABLE) {
		integrations.push(new CaptureConsoleIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_DEBUG_ENABLE) {
		integrations.push(new DebugIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_OFFLINE_ENABLE) {
		integrations.push(new OfflineIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_REPORTING_OBSERVER_ENABLE) {
		integrations.push(new ReportingObserverIntegration({}))
	}
	if (Config.VUE_APP_SENTRY_BROWSER_TRACING_ENABLE) {
		integrations.push(
			new Integrations.BrowserTracing({
				tracePropagationTargets: [Config.VUE_APP_ROOT_API],
				routingInstrumentation: SentryVue.vueRouterInstrumentation(router)
			})
		)
	}
	if (Config.VUE_APP_SENTRY_REPLAY_ENABLE) {
		integrations.push(
			new ReplayIntegration({
				// Options : https://github.com/rrweb-io/rrweb/blob/master/guide.md#options
				replaysSessionSampleRate: Config.VUE_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
				replaysOnErrorSampleRate: Config.VUE_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
				collectFonts: Config.VUE_APP_SENTRY_REPLAY_COLLECT_FONTS,
				inlineImages: Config.VUE_APP_SENTRY_REPLAY_INLINE_IMAGES,
				inlineStylesheet: Config.VUE_APP_SENTRY_REPLAY_INLINE_STYLESHEET,
				maskAllInputs: Config.VUE_APP_SENTRY_REPLAY_MASK_ALL_INPUTS,
				recordLog: Config.VUE_APP_SENTRY_REPLAY_RECORD_LOG,
				recordCanvas: Config.VUE_APP_SENTRY_REPLAY_RECORD_CANVAS
			})
		)
	}
	const release = process.env.FRONTEND_RELEASE || '0'
	const hooks = Config.VUE_APP_SENTRY_HOOKS.split(',')
	SentryVue.init({
		Vue,
		attachProps: Config.VUE_APP_SENTRY_ATTACH_PROPS,
		logErrors: Config.VUE_APP_SENTRY_LOG_ERRORS,
		dsn: Config.VUE_APP_SENTRY_DSN,
		integrations: integrations,
		environment: Config.VUE_APP_MODE,
		release: release,
		sampleRate: Config.VUE_APP_SENTRY_SAMPLE_RATE,
		tracesSampleRate: Config.VUE_APP_SENTRY_TRACE_SAMPLE_RATE,
		trackComponents: true,
		hooks: hooks,
		ignoreErrors: Config.VUE_APP_SENTRY_IGNORE_ERRORS,
		initialScope: {
			tags: {
				"workspace-domain": window.location.hostname
			},
		}
	})
}
