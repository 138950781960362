const DocumentsManager = () => import('@/modules/DocumentsManager')

export default [
	{
		component: DocumentsManager,
		name: 'documents',
		path: 'documents/:context?/:folderHash?',
		props: route => {
			return {
				context: route.params.context,
				documentHash: route.query.document_id,
				folderHash: route.params.folderHash,
				versionId: route.query.version_id ? parseInt(route.query.version_id) : undefined
			}
		}
	}
]
