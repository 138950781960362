// ============================================================================
// VueX - Mobile Apps
// ------------------
// Mobile Applications VueX store module
// ============================================================================
export default {
	namespaced: true,
	state: {
		selected: null,
		list: [],
		accountingFirms: []
	},
	getters: {
		selected: state => {
			return state.selected
		},
		list: state => {
			return state.list
		},
		accountingFirms: state => {
			return state.accountingFirms
		}
	},
	mutations: {
		setSelected: function (state, selected) {
			state.selected = selected
		},
		setList: function (state, list = []) {
			state.list.clear()
			state.list.push(...list)
		},
		setAccountingFirms: function (state, accountingFirms) {
			state.accountingFirms.clear()
			state.accountingFirms.push(...accountingFirms)
		},
		reset: function (state) {
			state.selected = null
			state.list.clear()
			state.accountingFirms.clear()
		}
	},
	actions: {
		setSelected: function ({ commit }, selected) {
			return commit('setSelected', selected)
		},
		setList: function ({ commit }, list) {
			return commit('setList', list)
		},
		setAccountingFirms: function ({ commit }, accountingFirms) {
			return commit('setAccountingFirms', accountingFirms)
		},
		reset: function ({ commit }) {
			return commit('reset')
		}
	}
}
