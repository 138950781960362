import Vue from 'vue'
import { Config } from '@/services/ConfigService'
import VueRouter from 'vue-router'

import routes from '@/router/routes'
import guards from '@/router/guards'
import plugins from '@/router/plugins'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

const filterNavigationFailures = function (err) {
	if (VueRouter.isNavigationFailure(err)) {
		return
	}
	return Promise.reject(err)
}

VueRouter.prototype.push = function push (location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	return originalPush.call(this, location).catch(filterNavigationFailures)
}
VueRouter.prototype.replace = function replace (location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalReplace.call(this, location, onResolve, onReject)
	}
	return originalReplace.call(this, location).catch(filterNavigationFailures)
}

VueRouter.prototype.encryptContext = function (accountingFirmId, vendorId) {
	let result = null
	if (accountingFirmId) {
		const maxCompanyNumber = Config.VUE_APP_MAX_COMPANY_NUMBER
		result = maxCompanyNumber * accountingFirmId
		if (vendorId) {
			result += vendorId
		}
		result = result.toString(16)
	}
	return result
}
VueRouter.prototype.decryptContext = function (context) {
	const result = {}
	if (context) {
		const decryptedContext = parseInt(context, 16)
		result.vendor_id = decryptedContext % Config.VUE_APP_MAX_COMPANY_NUMBER
		result.accounting_firm_id = (decryptedContext - result.vendor_id) / Config.VUE_APP_MAX_COMPANY_NUMBER
		if (result.vendor_id == 0) {
			result.vendor_id = null
		}
	}
	return result
}
const NON_HERITABLE_METAS = ['historize']
VueRouter.prototype.getMeta = function (route) {
	let result = {}

	const matchedRoutes = route.matched || []

	for (const matchedRoute of matchedRoutes) {
		let routeMeta = matchedRoute.meta
		if (typeof routeMeta == 'function') {
			routeMeta = routeMeta(matchedRoute)
		}
		Object.assign(result, routeMeta)
	}

	NON_HERITABLE_METAS.forEach(metaName => {
		delete result[metaName]
	})

	let routeMeta = route.meta
	if (typeof routeMeta == 'function') {
		routeMeta = routeMeta(route)
	}
	Object.assign(result, routeMeta)

	return result
}

const ROUTER = new VueRouter({
	base: Config.BASE_URL,
	saveScrollPosition: true,
	mode: 'history',
	routes: routes
})

export const router = ROUTER

export const isNavigationFailure = VueRouter.isNavigationFailure

export { sync } from 'vuex-router-sync'

ROUTER.beforeEach(function (to, from, next) {
	let result = guards(to, from, next)
	if (result === true) {
		result = plugins(to, from, next)
	}
	if (result === true) {
		next()
	} else {
		next(result)
	}
})
