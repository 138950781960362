import AuthService from '@/services/Auth/AuthService'

const HomeScreen = () => import('@/modules/HomeScreen')

export default [
	{
		component: HomeScreen,
		meta: function () {
			const result = {}

			if (AuthService.getIsAccountant()) {
				result.context = {
					vendor_id: 'never'
				}
			}

			return result
		},
		name: 'home',
		path: 'home/:context?'
	}
]
