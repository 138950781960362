/**
 * Vuetify plugin
 * --------------
 * Used to provide a quick way to have a modern webapp style.
 */
// Vuetify customization through stylus
import 'vuetify/dist/vuetify.min.css'

// Text Font
import 'roboto-fontface/css/roboto/roboto-fontface.css'

// Material design icons
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Fontawesome icons
import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import 'babel-polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
// Vuetify Add-ons
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.min.css'

// Supported languages
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'
import pt from 'vuetify/es5/locale/pt'

// Material design colors
import colors from 'vuetify/es5/util/colors'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

library.add(fas)
let locale = navigator.language
if (locale) {
	locale = locale.substring(0, 2)
}
if (!['en', 'fr', 'pt'].includes(locale)) {
	locale = 'fr'
}
Vue.use(Vuetify, {
	theme: {
		primary: '#2DC092',
		secondary: '#21375C',
		accent: colors.blue.accent1,
		error: colors.red.accent2,
		info: colors.blue.base,
		success: colors.green.base,
		warning: colors.amber.base,
		softGrey: colors.grey.lighten4
	},
	options: {
		customProperties: true
	},
	iconfont: 'md' || 'mdi' || 'fa' || 'faSvg' || 'mdiSvg',
	lang: {
		locales: {
			fr,
			en,
			pt
		},
		current: locale
	}
})

Vue.use(VuetifyDialog)
